(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideRatesDetailController', GuideRatesDetailController);

    GuideRatesDetailController.$inject = ['$rootScope', '$scope', '$timeout', '$translate', '$uibModalInstance', '$uibModal', '$http',
        'DateUtils', 'GuideRates', 'entity', 'Guide', 'OpsExchangeRate', 'DataUtils', 'OpsVender', 'WmsIn', 'OpsItem', 'OpsRates', 'Dictionary',
        'OpsFactory', 'Booking', 'OpsCost', 'OpsReport', 'ERateUtils', 'OpsFiles', 'AccountPayment', 'AccountInvoice', 'ExpressParcel', 'OpsBill', 
        'AssistCharge'
    ];

    function GuideRatesDetailController($rootScope, $scope, $timeout, $translate, $uibModalInstance, $uibModal, $http,
        DateUtils, GuideRates, entity, Guide, OpsExchangeRate, DataUtils, OpsVender, WmsIn, OpsItem, OpsRates, Dictionary,
        OpsFactory, Booking, OpsCost, OpsReport, ERateUtils, OpsFiles, AccountPayment, AccountInvoice, ExpressParcel, OpsBill,
        AssistCharge) {

        $scope.guide = {};
        $scope.shipmentType = entity.shipmentType;
        $scope.guidesList = entity.guidesList;
        var sellingCost = entity.sellingCost;

        var exchangeRates = null;
        var guideCopy = entity.guide;
        $scope.jobNumCopy = null;
        $scope.files = [];

        $timeout(function() {
            if($rootScope.HAVENOAUTH("AU_VIEW_CHARGES")) {
                $uibModalInstance.dismiss('cancel');
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_CHARGES');
                return;
            };
            loadGuide();
            if($rootScope.isYuyueExHaShen($rootScope.account.companyId)){
                $scope.hideHedgingChargesRev = true;
                $scope.hideHedgingChargesExp = true;
            }
            $scope.ttlFilter.byActualRoe = $rootScope.HAVEAUTH("BAROE");
        })

        $scope.getExchangeRatesMap = function(bizDate) {
            $rootScope.loadCurrencys();
            if (!bizDate) {
                bizDate = new Date();
            }
            OpsExchangeRate.getExchangeRatesMap({
                date: DateUtils.convertLocalDateToServer(bizDate)
            }, function(result) {
                exchangeRates = result;
                updateTotal();
            })
        }
        $scope.loadGuide = function() {
            layer.confirm($translate.instant('autopsApp.guide.refreshAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                loadGuide();
                layer.close(index);
            }, function() {});
        }


        var loadGuide = function(isYhsp) {
            var loadingIdx = layer.load(1, {shade: 0});
            $scope.isSaving = true;
            if ($scope.shipmentType == 'guide') {
                Guide.getGuideAndRates({
                    id: guideCopy.id
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    $scope.jobNumCopy = result.jobNum;
                    $scope.isSaving = false;
                    layer.close(loadingIdx);
                    $scope.$emit('autopsApp:guideRatesUpdate', result);
                    initVirtual();
                    $scope.files = [];
                    if(isYhsp == true && $rootScope.IS_MASTER($scope.guide)){
                        autoCostShare();
                    }
                })
            } else if ($scope.shipmentType == 'wms') {
                WmsIn.getGuideRates({
                    id: guideCopy.id
                }, function(result) {                    
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    $scope.jobNumCopy = result.warehousingNumber;
                    $scope.isSaving = false;
                    layer.close(loadingIdx);
                    initVirtual();
                    $scope.files = [];
                })
            } else {
                ExpressParcel.getGuideAndRates({
                    id: guideCopy.id
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    $scope.jobNumCopy = result.jobNum;
                    $scope.isSaving = false;
                    layer.close(loadingIdx);
                    initVirtual();
                    $scope.$emit('autopsApp:guideRatesUpdate', result);
                    $scope.files = [];
                    if(isYhsp == true && $rootScope.IS_MASTER($scope.guide)){
                        autoCostShare();
                    }
                })
            }
        }
        function autoCostShare () {
            layer.confirm("当前费用是否按计费重进行分摊？", {
                inon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                $scope.shareChargesDialog(true);
                layer.close(index);
            }, function() {
            });
        }

        $scope.isVMed = false;
        $scope.isVFinal = false;
        function initVirtual () {
            if($scope.guide.companyId != $rootScope.account.companyId 
                && $scope.guide.medCompanyId != $rootScope.account.companyId){
                layer.msg($translate.instant('global.forbidden'));
                $scope.clear();
            }
            
            angular.forEach($scope.guide.guideRates, function(val, idx) {
                if(!val.idx && val.idx != 0){
                    val.idx = idx;
                }
                if(!val.locked && !val.dnNum){
                    val.exchangeRate = null;
                }
            })
            $scope.guide.guideRates.sort(function(a, b) {
                return a.idx > b.idx ? 1 : -1;
            })
            guideCopy = angular.copy($scope.guide);
            if($scope.shipmentType == "wms"){
                document.title = guideCopy.warehousingNumber;
            }
            else {
                document.title = guideCopy.jobNum;
            }
            if(($scope.guide.companyId == 305 || $scope.guide.companyId == 328)
                    && $scope.guide.isClosed && $rootScope.HAVENOAUTH("ROLE_BOSS")
                    && $rootScope.HAVENOAUTH("ROLE_BRANCH") && $rootScope.HAVENOAUTH("ROLE_DEPARTMENT")) {
                $uibModalInstance.dismiss('cancel');
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_DEPARTMENT');
                return;
            };
            if($scope.guide.isVirtual){
                if(!$scope.guide.medCompanyId || $scope.guide.medCompanyId == $rootScope.account.companyId){
                    $scope.isVMed = true;
                }
                else {
                    $scope.isVFinal = true;
                }
            }
            if(sellingCost){
                $scope._onFmsPriceSelected(sellingCost, true);
            }
            if($rootScope.isYuyueExHaShen($scope.guide.companyId) && $rootScope.IS_MASTER($scope.guide)){
                if(angular.isUndefined($scope.hideShareChargesExp)){
                    $scope.hiddenChange('hideShareChargesExp');
                }
                if(angular.isUndefined($scope.hideShareChargesRev)){
                    $scope.hiddenChange('hideShareChargesRev');
                }
            }
            getCtnsNum();
        }
        $scope.saveRates = function() {
            if ($rootScope.HAVENOAUTH("AU_AMEND_CHARGES")) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_CHARGES');
                return;
            }
            if ($scope.jobRateLocked()) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            if (isSalesOnly() && $scope.guide.isLocked && $rootScope.account.companyId !== 234) {
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            if(!$scope.guide.guideRates){
                $scope.guide.guideRates = [];
            }

            for (var i = 0; i < $scope.guide.guideRates.length; i++) {
                if (!$scope.guide.guideRates[i]) {
                    continue;
                }
                if(!$scope.guide.guideRates[i].item && !$scope.guide.guideRates[i].dnNum){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.itemNullAlert'), {icon:0});
                    return;
                }
                if($rootScope.HAVEAUTH('PAYER_MUST') && !$scope.guide.guideRates[i].dnNum
                    && (!$scope.guide.guideRates[i].payer || !$scope.guide.guideRates[i].venderId)){
                    $rootScope.OPS_ALERT($scope.guide.guideRates[i].item + "： 结算对象不能为空", {icon:0});
                    return;
                }
                if($rootScope.HAVEAUTH('CUR_MUST') && !$scope.guide.guideRates[i].dnNum){
                    if(!$scope.guide.guideRates[i].currency || !$scope.guide.guideRates[i].payCurrency){
                        $rootScope.OPS_ALERT($scope.guide.guideRates[i].item + "： 币种不能为空", {icon:0});
                        return;
                    }
                }
                if(!$scope.guide.guideRates[i].id){
                    if($rootScope.account.companyId == $scope.guide.companyId || $rootScope.account.companyId == $scope.guide.medCompanyId){
                        $scope.guide.guideRates[i].companyId = $rootScope.account.companyId;
                    }
                    else {
                        $scope.guide.guideRates[i].companyId = $scope.guide.companyId;
                    }
                }

                if (guideCopy.guideRates && $scope.guide.guideRates[i].id) {
                    for (var j = 0; j < guideCopy.guideRates.length; j++) {
                        if (guideCopy.guideRates[j] && $scope.guide.guideRates[i].id == guideCopy.guideRates[j].id && !angular.equals($scope.guide.guideRates[i], guideCopy.guideRates[j])) {
                            $scope.guide.guideRates[i].lastModifiedTime = new Date();
                            $scope.guide.guideRates[i].lastModifiedBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                        }
                    }
                }
            }
            updateRatesIdx();
            $scope.isSaving = true;
            if ($scope.shipmentType == 'guide') {
                $scope.guide.profit = $scope.profit.ammount;
                Guide.saveGuideAndRates($scope.guide, onSaveSuccess, onSaveError);
            }
            else if ($scope.shipmentType == 'wms') {
                WmsIn.saveGuideRates($scope.guide, onSaveSuccess, onSaveError);
            }
            else {
                ExpressParcel.saveGuideAndRates($scope.guide, onSaveSuccess, onSaveError);
            }
        }

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            if (!result.etd) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.noEtdAlert'));
            } else {
                layer.msg($translate.instant('global.messages.submitted'));
            }
            if($rootScope.isYuyue($scope.guide.companyId)){
                loadGuideAsyn();
            }
            else {
                loadGuide();
            }
            
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        $scope.goBack = function() {
            if (!angular.equals($scope.guide, guideCopy)) {
                layer.confirm($translate.instant('global.notSavedAlert'), {
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    $scope.clear();
                    layer.close(index);
                }, function() {});
                return;
            } else {
                $scope.clear();
            }
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
            $scope.$emit('autopsApp:guideRatesUpdate', guideCopy);
        };

        $scope.profit = {};
        var profitCopy = 0;
        $scope.costTotalList = [];
        $scope.sellingTotalList = [];
        $scope.profitList = [];

        $scope.profitExTax = {};
        var profitCopyExTax = 0;
        $scope.costTotalListExTax = [];
        $scope.sellingTotalListExTax = [];
        $scope.profitListExTax = [];

        $scope.ttlFilter = {};
        var updateTotal = function() {
            if (!exchangeRates) {
                return;
            }
            var ratesCopy = angular.copy($scope.guide.guideRates);
            if(ratesCopy && ($scope.ttlFilter.exAddCharges || $scope.ttlFilter.exShareCharge)){
                for(var i = ratesCopy.length -1; i >= 0; i--){
                    if($scope.ttlFilter.exAddCharges
                        && (!ratesCopy[i].accountDate
                            || !DateUtils.isSameMonth($scope.guide.etd, ratesCopy[i].accountDate))){
                        ratesCopy.splice(i, 1);
                    }
                    else if($scope.ttlFilter.exShareCharge
                        && ratesCopy[i].dnNum && ratesCopy[i].dnNum.indexOf("CS") == 0) {
                        ratesCopy.splice(i, 1);
                    }
                }
            }
            $scope.costTotalList = [];
            $scope.sellingTotalList = [];
            $scope.profitList = [];

            $scope.costTotalListExTax = [];
            $scope.sellingTotalListExTax = [];
            $scope.profitListExTax = [];

            var currencys = [];
            angular.forEach(ratesCopy, function(data) {
                if (!data.isHidden && data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);
                }
            })
            currencys.sort(function(a,b){
                return a > b?-1:1;
            });

            var cost = 0;
            var selling = 0;
            var profit = 0;
            var profitTtl = 0;

            var costExTax = 0;
            var sellingExTax = 0;
            var profitExTax = 0;
            var profitTtlExTax = 0;
            var roeAmt = 1;
            var amtExTax = 0;
            angular.forEach(currencys, function(currency) {
                cost = 0;
                selling = 0;
                profit = 0;

                costExTax = 0;
                sellingExTax = 0;
                profitExTax = 0;

                angular.forEach(ratesCopy, function(rate) {
                    if (!rate.isHidden && rate.payCurrency == currency && rate.payAmount) {
                        if ($scope.ttlFilter.byActualRoe && rate.actualExchangeRate) {
                            roeAmt = rate.actualExchangeRate;
                        } else if (rate.exchangeRate) {
                            roeAmt = rate.exchangeRate;
                        } else {
                            roeAmt = exchangeRates[currency];
                        }
                        amtExTax = 0;
                        if (rate.isPay) {
                            cost = cost + rate.payAmount;

                            profitTtl = profitTtl - (rate.payAmount * roeAmt);
                            // if(rate.exchangeRate){
                            //     profitTtl = profitTtl - DataUtils.round(rate.payAmount * roeAmt, 2);
                            // }
                            // else {
                            //     profitTtl = profitTtl - (rate.payAmount * roeAmt);
                            // }

                            if ($scope.guide.vat || $scope.guide.incomeTax) {
                                amtExTax = rate.payAmount;
                                if (rate.vatAmt) {
                                    amtExTax = amtExTax - rate.vatAmt;
                                }
                                if (rate.incomeTaxAmt) {
                                    amtExTax = amtExTax + rate.incomeTaxAmt;
                                }
                                costExTax = costExTax + amtExTax;
                                profitTtlExTax = profitTtlExTax - amtExTax * roeAmt;
                            }
                        } else {
                            selling = selling + rate.payAmount;

                            profitTtl = profitTtl + (rate.payAmount * roeAmt);
                            // if(rate.exchangeRate){
                            //     profitTtl = profitTtl + DataUtils.round(rate.payAmount * roeAmt, 2);
                            // }
                            // else {
                            //     profitTtl = profitTtl + (rate.payAmount * roeAmt);
                            // }

                            if ($scope.guide.vat || $scope.guide.incomeTax) {
                                amtExTax = rate.payAmount;
                                if (rate.vatAmt) {
                                    amtExTax = amtExTax - rate.vatAmt;
                                }
                                if (rate.incomeTaxAmt) {
                                    amtExTax = amtExTax + rate.incomeTaxAmt;
                                }
                                sellingExTax = sellingExTax + amtExTax;
                                profitTtlExTax = profitTtlExTax + amtExTax * roeAmt;
                            }
                        }
                    }
                })
                $scope.costTotalList.push({
                    currency: currency,
                    total: DataUtils.round(cost, 2)
                });
                $scope.costTotalListExTax.push({
                    currency: currency,
                    total: DataUtils.round(costExTax, 2)
                });

                $scope.sellingTotalList.push({
                    currency: currency,
                    total: DataUtils.round(selling, 2)
                });
                $scope.sellingTotalListExTax.push({
                    currency: currency,
                    total: DataUtils.round(sellingExTax, 2)
                });

                profit = selling - cost;
                profitExTax = sellingExTax - costExTax;

                $scope.profitList.push({
                    currency: currency,
                    total: DataUtils.round(profit, 2)
                });
                $scope.profitListExTax.push({
                    currency: currency,
                    total: DataUtils.round(profitExTax, 2)
                });

            })
            profitCopy = DataUtils.round(profitTtl, 2);
            profitCopyExTax = DataUtils.round(profitTtlExTax, 2);

            $scope.profit.ammount = DataUtils.round(profitTtl, 2);
            $scope.profitExTax.ammount = DataUtils.round(profitTtlExTax, 2);

            if (!$scope.profit.currency) {
                $scope.profit.currency = $rootScope.account.baseCurrency;
            }
            if (!$scope.profitExTax.currency) {
                $scope.profitExTax.currency = $rootScope.account.baseCurrency;
            }
            updateAssistChangeTotal();
        }
        $scope.updateTotalDo = function() {
            updateTotal();
        }
        var isOp = function() {
            var authorities = ["ROLE_BOSS", "ROLE_MARKET", "ROLE_DOCUMENT", "ROLE_OPERATOR"];
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return false;
            }
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };
        var isOpOrSales = function() {
            var authorities = ["ROLE_BOSS", "ROLE_DOCUMENT", "ROLE_MARKET", "ROLE_OPERATOR", "ROLE_SALES", "ROLE_CS"];
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return false;
            }
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };
        var isSalesOnly = function() {
            var authorities = ["ROLE_BOSS", "ROLE_DOCUMENT", "ROLE_MARKET", "ROLE_OPERATOR", "ROLE_ACCOUNT", "ROLE_CASHIER", "ROLE_CS"];
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return true;
            }
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                    return false;
                }
            }
            return true;
        };


        $scope.jobRateLocked = function() {
            return ($scope.guide.ratesLocked || $scope.guide.isClosed) && $rootScope.HAVENOAUTH('AU_FEE_FORCED_MODIFICATION');
        }

        $scope.addOne = function(isPay, rate) {
            if ($scope.jobRateLocked()) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if (!isOp() && $scope.guide.isLocked && $rootScope.HAVENOAUTH('AU_AMEND_CHARGES')) {
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            if (!$scope.guide.guideRates) {
                $scope.guide.guideRates = [];
            }
            if (!rate) {
                rate = {};
                if (isPay) {
                    rate.venderId = $scope.guide.bookingToId;
                    rate.payer = $scope.guide.bookingToName == null ? "" : $scope.guide.bookingToName;
                } else {
                    rate.venderId = $scope.shipmentType == "wms" ? $scope.guide.venderId : $scope.guide.shipperId;
                    rate.payer = $scope.shipmentType == "wms" ? $scope.guide.clientName : $scope.guide.shipperName;
                    if($scope.guide.isVirtual && $scope.guide.medCompanyId != $rootScope.account.companyId){
                        rate.venderId = $scope.guide.medVenderId;
                        rate.payer = $scope.guide.medCompany;
                    }
                }
            }

            if (isPay) {
                $scope.guide.guideRates.push({
                    "itemId": "",
                    "item": "",
                    "currency": $rootScope.account.baseCurrency,
                    "venderId": rate.venderId,
                    "unit": "BL",
                    "count": 1,
                    "isPay": true,
                    "isHidden": false,
                    "locked": false,
                    "paymentTerm": "PPD",
                    "payer": rate.payer
                });
            } else {
                $scope.guide.guideRates.push({
                    "itemId": "",
                    "item": "",
                    "currency": $rootScope.account.baseCurrency,
                    "venderId": rate.venderId,
                    "unit": "BL",
                    "count": 1,
                    "isPay": false,
                    "isHidden": false,
                    "locked": false,
                    "paymentTerm": "PPD",
                    "payer": rate.payer
                });
            }
            $translate.refresh();
        };
        $scope.deleteRate = function(rate, isForce) {
            if (rate.id && $rootScope.HAVENOAUTH("AU_AMEND_CHARGES")) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_CHARGES');
                return;
            }
            if (rate.id && rate.internalControl == 'mustInput' && $rootScope.HAVENOAUTH("AU_DEL_MUST_CHARGE")) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_MUST_CHARGE');
                return;
            }

            if (isSalesOnly() && $scope.guide.isLocked && $rootScope.account.companyId !== 234) {
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            if (rate.sharedOn) {
                layer.msg($translate.instant('autopsApp.booking.bookingPrice.delete.sharedAlert'));
                return;
            }
            if (!isOp() && $scope.guide.isLocked && $rootScope.HAVENOAUTH('AU_AMEND_CHARGES')) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }

            if (!rate.id) {
                var index = $scope.guide.guideRates.indexOf(rate);
                if (index > -1) {
                    $scope.guide.guideRates.splice(index, 1);
                };
                updateTotal();
                $translate.refresh();
                return;
            };
            if ($scope.jobRateLocked()) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if (rate.billNum || rate.locked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            var content = $translate.instant('autopsApp.booking.bookingPrice.delete.question');
            if (rate.sharedOn) {
                isForce = false;
                content = $translate.instant('autopsApp.booking.bookingPrice.delete.sharedAlert');
            }
            else if (rate.refType && rate.refType.indexOf("storage_") == 0){
                content = "删除库存计费，系统会自动回滚库存的计费日期，但已发货订单可能会有仓租漏收费（需要人工核对，并重新计费），确认要删除吗？"
                isForce = false;
            }
            if(isForce){
                if (rate.id) {
                    GuideRates.delete({
                        id: rate.id
                    }, function(){
                        if(rate.isPay && $rootScope.isDLX($scope.guide.companyId)){
                            $scope.guide.salesChecked = false;
                        }
                    });
                };
                var index = $scope.guide.guideRates.indexOf(rate);
                if (index > -1) {
                    $scope.guide.guideRates.splice(index, 1);
                };
                updateTotal();
                $translate.refresh();
                return;
            }
            layer.confirm(content, {
                icon: 3,
                title: $translate.instant('autopsApp.booking.bookingPrice.delete.title'),
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                if (rate.id) {
                    GuideRates.delete({
                        id: rate.id
                    }, function(){
                        if(rate.isPay && $rootScope.isDLX($scope.guide.companyId)){
                            $scope.guide.salesChecked = false;
                        }
                    });
                };
                var index = $scope.guide.guideRates.indexOf(rate);
                if (index > -1) {
                    $scope.guide.guideRates.splice(index, 1);
                };
                updateTotal();
                $translate.refresh();
            }, function() {
                return;
            });
        };
        var checkPayerVid = function() {
            angular.forEach($scope.guide.guideRates, function(data) {
                if (!data.venderId) {
                    data.payer = "";
                }
            })
        }

        $scope.payAmountCheck = function(rate) {
            updateTotal();
        }
        $scope.amountChange = function(rate) {
            if ($scope.jobRateLocked() || rate.dnNum) {
                return;
            }
            DataUtils.rateAmountChange(rate, $scope.guide);
            updateTotal();
        }

        $scope.taxChange = function(fd) {
            if(!$scope.guide[fd]){
                var vatLocked = false;
                angular.forEach($scope.guide.guideRates, function(rate) {
                    if(rate.dnNum && rate.vat && fd == "vat"){
                        vatLocked = true;
                    }
                    if(rate.dnNum && rate.incomeTax && fd == "incomeTax"){
                        vatLocked = true;
                    }
                })
                if(vatLocked){
                    $scope.guide[fd] = !$scope.guide[fd];
                    layer.msg($translate.instant('global.ratesLockedAlert'));
                    return;
                }
            }

            angular.forEach($scope.guide.guideRates, function(rate) {
                if(!rate.dnNum){
                    if (!$scope.guide.vat) {
                        rate.vat = 0;
                        rate.vatAmt = 0;
                    }
                    if (!$scope.guide.incomeTax) {
                        rate.incomeTax = 0;
                        rate.incomeTaxAmt = 0;
                    }
                    $scope.amountChange(rate);
                }
            })
        }

        $scope.loadAllPayer = function() {
            OpsVender.query({
                loadBySql: true
            }, function(result) {
                $rootScope.OPSVENDERS = result;
                $scope.payers = [];
                angular.forEach($rootScope.OPSVENDERS, function(data) {       
                    data.branch = null;
                    data.team = null;
                    data.cusName = null;
                    data.creater = null;
                    if(!data.blacklist){  
                        $scope.payers.push(data);
                    }
                })
                layer.msg($translate.instant('entity.action.loadSuccess'));
            });
        }

        $scope.loadDefultPayer = function(id, index, rate) {
            if (id && index != null) {
                $("#" + id + index).select();
            }
            if (rate && !rate.payer) {
                rate.payer = "";
            }
            if (!$scope.payers || $scope.payers.length == 0) {
                GuideRates.getDefaultPayer({
                    id: $scope.guide.id,
                    companyId: $rootScope.account.companyId,
                    type: $scope.shipmentType
                }, function(result){
                    $scope.payers = result;
                })
            }
        };

        $scope._onItemSelected = function(data, rate) {
            rate.itemId = data.id;
            rate.internalControl = data.internalControl;
            if(rate.internalControl == "autoHide"){
               rate.hiddenRate = true;
            }
            if ($translate.instant("autopsApp.booking.opsItem.cnen") == "nameEn") {
                rate.item = data.nameEn;
            } else {
                rate.item = data.nameCn;
            };
            if (data.currency) {
                rate.currency = data.currency;
                rate.payCurrency = data.currency;
            }
            if (data.unit) {
                rate.unit = data.unit;
            }

            if(units.indexOf(rate.unit) === -1){
                rate.autoUpdate = false;
            }

            if(data.price){
                rate.price = data.price;
            }
            if (data.payeeType && rate.isPay) {
                if (data.payeeType == -1 && $scope.guide.customerId) {
                    rate.venderId = $scope.guide.customerId;
                    rate.payer = $scope.guide.customerName;
                } else if (data.payeeType == -2 && $scope.guide.shipperId) {
                    rate.venderId = $scope.guide.shipperId;
                    rate.payer = $scope.guide.shipperName;
                    if($scope.guide.isVirtual && $scope.guide.medCompanyId != $rootScope.account.companyId){
                        rate.venderId = $scope.guide.medVenderId;
                        rate.payer = $scope.guide.medCompany;
                    }
                } else if (data.payeeType == -3 && $scope.guide.bookingToId) {
                    rate.venderId = $scope.guide.bookingToId;
                    rate.payer = $scope.guide.bookingToName;
                } else if (data.payeeType == -4 && $scope.guide.deliveryId) {
                    rate.venderId = $scope.guide.deliveryId;
                    rate.payer = $scope.guide.deliveryName;
                } else if (data.payeeType == -5 && $scope.guide.booking.bookingTrucks && $scope.guide.booking.bookingTrucks.length > 0 && $scope.guide.booking.bookingTrucks[0].truckerVid) {
                    rate.venderId = $scope.guide.booking.bookingTrucks[0].truckerVid;
                    rate.payer = $scope.guide.booking.bookingTrucks[0].truckerName;
                } else if (data.payeeType == -6) {
                    if($scope.guide.bookingCustoms && $scope.guide.bookingCustoms.length > 0){
                        rate.venderId = $scope.guide.bookingCustoms[0].brokerVid;
                        rate.payer = $scope.guide.bookingCustoms[0].brokerName;
                    }
                } else if (data.payeeType == -7 && $scope.guide.warehouseVid) {
                    rate.venderId = $scope.guide.warehouseVid;
                    rate.payer = $scope.guide.warehouseName;
                } else if (data.payeeType == -8 && $scope.guide.traderVid) {
                    rate.venderId = $scope.guide.traderVid;
                    rate.payer = $scope.guide.traderName;
                } else if (data.payeeType == -9 && $scope.guide.insuranceVid) {
                    rate.venderId = $scope.guide.insuranceVid;
                    rate.payer = $scope.guide.insuranceName;
                } else if (data.payeeType == -10 && $scope.guide.fumigationVid) {
                    rate.venderId = $scope.guide.fumigationVid;
                    rate.payer = $scope.guide.fumigationName;
                } else if (data.payeeType == -11 && $scope.guide.clearanceAgentId) {
                    rate.venderId = $scope.guide.clearanceAgentId;
                    rate.payer = $scope.guide.clearanceAgent;
                }
            }
            else if (data.payerType && !rate.isPay) {
                if (data.payerType == -1 && $scope.guide.customerId) {
                    rate.venderId = $scope.guide.customerId;
                    rate.payer = $scope.guide.customerName;
                } else if (data.payerType == -2 && $scope.guide.shipperId) {
                    rate.venderId = $scope.guide.shipperId;
                    rate.payer = $scope.guide.shipperName;
                    if($scope.guide.isVirtual && $scope.guide.medCompanyId != $rootScope.account.companyId){
                        rate.venderId = $scope.guide.medVenderId;
                        rate.payer = $scope.guide.medCompany;
                    }
                } else if (data.payerType == -3 && $scope.guide.bookingToId) {
                    rate.venderId = $scope.guide.bookingToId;
                    rate.payer = $scope.guide.bookingToName;
                } else if (data.payerType == -4 && $scope.guide.deliveryId) {
                    rate.venderId = $scope.guide.deliveryId;
                    rate.payer = $scope.guide.deliveryName;
                } else if (data.payerType == -5 && $scope.guide.booking.bookingTrucks && $scope.guide.booking.bookingTrucks.length > 0 && $scope.guide.booking.bookingTrucks[0].truckerVid) {
                    rate.venderId = $scope.guide.booking.bookingTrucks[0].truckerVid;
                    rate.payer = $scope.guide.booking.bookingTrucks[0].truckerName;
                } else if (data.payeeType == -6) {
                    if($scope.guide.bookingCustoms && $scope.guide.bookingCustoms.length > 0){
                        rate.venderId = $scope.guide.bookingCustoms[0].brokerVid;
                        rate.payer = $scope.guide.bookingCustoms[0].brokerName;
                    }
                } else if (data.payerType == -7 && $scope.guide.warehouseVid) {
                    rate.venderId = $scope.guide.warehouseVid;
                    rate.payer = $scope.guide.warehouseName;
                } else if (data.payerType == -8 && $scope.guide.traderVid) {
                    rate.venderId = $scope.guide.traderVid;
                    rate.payer = $scope.guide.traderName;
                } else if (data.payerType == -9 && $scope.guide.insuranceVid) {
                    rate.venderId = $scope.guide.insuranceVid;
                    rate.payer = $scope.guide.insuranceName;
                } else if (data.payerType == -10 && $scope.guide.fumigationVid) {
                    rate.venderId = $scope.guide.fumigationVid;
                    rate.payer = $scope.guide.fumigationName;
                } else if (data.payerType == -11 && $scope.guide.clearanceAgentId) {
                    rate.venderId = $scope.guide.clearanceAgentId;
                    rate.payer = $scope.guide.clearanceAgent;
                }
            } else if (rate.isPay && data.id == 13) {
                if($scope.guide.bookingCustoms && $scope.guide.bookingCustoms.length > 0){
                    rate.venderId = $scope.guide.bookingCustoms[0].brokerVid;
                    rate.payer = $scope.guide.bookingCustoms[0].brokerName;
                }
            } else if (rate.isPay && data.id == 4 && $scope.guide.booking.bookingTrucks && $scope.guide.booking.bookingTrucks.length > 0 && $scope.guide.booking.bookingTrucks[0].truckerVid) {
                rate.venderId = $scope.guide.booking.bookingTrucks[0].truckerVid;
                rate.payer = $scope.guide.booking.bookingTrucks[0].truckerName;
            } else if (rate.isPay && (data.id == 1 || data.id == 2 || data.id == 7 || data.id == 10 || data.id == 12) && $scope.guide.bookingToId) {
                rate.venderId = $scope.guide.bookingToId;
                rate.payer = $scope.guide.bookingToName;
            } else if (rate.isPay && data.id == 5 && $scope.guide.fumigationVid) {
                rate.venderId = $scope.guide.fumigationVid;
                rate.payer = $scope.guide.fumigationName;
            } else if (rate.isPay && data.id == 8 && $scope.guide.insuranceVid) {
                rate.venderId = $scope.guide.insuranceVid;
                rate.payer = $scope.guide.insuranceName;
            } else if (rate.isPay && data.id == 9 && $scope.guide.traderVid) {
                rate.venderId = $scope.guide.traderVid;
                rate.payer = $scope.guide.traderName;
            } else if (rate.isPay && data.id == 6 && $scope.guide.warehouseVid) {
                rate.venderId = $scope.guide.warehouseVid;
                rate.payer = $scope.guide.warehouseName;
            } else if (rate.isPay && data.id > 15 && data.id < 21 && $scope.guide.deliveryId) {
                rate.venderId = $scope.guide.deliveryId;
                rate.payer = $scope.guide.deliveryName;
            }

            if(rate.unit == "CTNTYPE VAR"){
                if($scope.guide.booking && $scope.guide.guideFclCtns){
                    DataUtils.ctnTypeVarInput(rate, data, $scope.guide, $scope.shipmentType);
                }
            }
            else {
                rate.count = DataUtils.round(DataUtils.getUnitCount(rate, $scope.shipmentType, $scope.guide), 4);
                $scope.amountChange(rate);
                minMaxCheck(rate, data);
            }
            if(rate.internalControl){
                layer.msg($translate.instant('autopsApp.guide.' + rate.internalControl + 'Tips'));
            }
        }

        var minMaxCheck = function(rate, item) {
            DataUtils.rateMinMaxCheck(rate, item, $scope.guide);
        }

        $scope.copySelling = function() {
            for (var i = 0; i < $scope.guide.guideRates.length; i++) {
                if(isHiddenRate($scope.guide.guideRates[i])){
                    continue;
                }
                if (!$scope.guide.guideRates[i].isPay && $scope.guide.guideRates[i].payAmount > 0) {
                    var tempRate = angular.copy($scope.guide.guideRates[i]);
                    DataUtils.clearCopyRate(tempRate);
                    tempRate.isPay = true;
                    tempRate.inputBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                    tempRate.venderId = $scope.guide.bookingToId;
                    tempRate.payer = $scope.guide.bookingToName;
                    $scope.guide.guideRates.push(tempRate);
                }
            }
            updateTotal();
        }

        $scope.copyCost = function() {
            for (var i = 0; i < $scope.guide.guideRates.length; i++) {
                if(isHiddenRate($scope.guide.guideRates[i])){
                    continue;
                }
                if ($scope.guide.guideRates[i].isPay && $scope.guide.guideRates[i].payAmount > 0) {
                    var tempRate = angular.copy($scope.guide.guideRates[i]);
                    DataUtils.clearCopyRate(tempRate);
                    tempRate.isPay = false;
                    tempRate.inputBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                    tempRate.venderId = $scope.shipmentType == "wms" ? $scope.guide.venderId : $scope.guide.shipperId;
                    tempRate.payer = $scope.shipmentType == "wms" ? $scope.guide.clientName : $scope.guide.shipperName;
                    if($scope.guide.isVirtual && $scope.guide.medCompanyId != $rootScope.account.companyId){
                        tempRate.venderId = $scope.guide.medVenderId;
                        tempRate.payer = $scope.guide.medCompany;
                    }
                    $scope.guide.guideRates.push(tempRate);
                }
            }
            updateTotal();
        }
        $scope.generateSelling = function() {
            angular.forEach(ERateUtils.getSelling($scope.guide), function(rate) {
                $scope.guide.guideRates.push(rate);
            })
            $translate.refresh();

        }
        $scope.generateCost = function() {
            angular.forEach(ERateUtils.getCost($scope.guide), function(rate) {
                $scope.guide.guideRates.push(rate);
            })
            $translate.refresh();
        }
        $scope.currencyChange = function(rate) {
            rate.payCurrency = rate.currency;
            $scope.payCurrencyChange(rate);
        }
        $scope.payCurrencyChange = function(rate) {
            if (rate.currency == rate.payCurrency) {
                rate.payExchange = 1.00;
                $scope.amountChange(rate);
                return;
            }
            if (!rate.currency || !rate.payCurrency) {
                return;
            }
            OpsExchangeRate.getGuidingRate({
                baseCurrency: $rootScope.account.baseCurrency,
                originalCurrency: rate.currency,
                targetCurrency: rate.payCurrency,
                companyId: $rootScope.account.companyId,
                isPay: rate.isPay
            }, function(result) {
                rate.payExchange = result.rate;
                $scope.amountChange(rate);
            })
        }

        $scope.shareChargesDialog = function(isPay) {
            if ($rootScope.HAVENOAUTH('AU_CHARGES_SHARE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_CHARGES_SHARE');
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!isOp()) {
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            var shareMCostDTO = {};
            var masterGuide = angular.copy($scope.guide);
            filterHidden(masterGuide.guideRates);
            shareMCostDTO.masterGuide = masterGuide;

            shareMCostDTO.isPay = isPay;
            shareMCostDTO.masterId = $scope.guide.id;
            shareMCostDTO.isFms = $scope.shipmentType == "guide";

            $uibModal.open({
                templateUrl: 'app/entities/guideRates/share-charges-dialog.html',
                controller: 'ShareChargesDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return shareMCostDTO;
                    }]
                }
            }).result.then(function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
                loadGuide();
            }, function(result) {

            });
        };

        var isReverse = true;
        $scope.sortRates = function(fd) {
            isReverse = !isReverse;
            $scope.guide.guideRates.sort(function(a, b) {
                if (isReverse) {
                    if(!a[fd]){
                        return 1;
                    }
                    return a[fd] < b[fd] ? 1 : -1;
                } else {
                    if(!b[fd]){
                        return 1;
                    }
                    return a[fd] > b[fd] ? 1 : -1;
                }
            });
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.amendEtd = function() {
            if (!$scope.guide.etdLocked) {
                return;
            }
            if($scope.isVMed){
                return;
            }
            if ($scope.shipmentType == "guide" && !isOp() && $scope.guide.isLocked) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }

            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var guideDto = {};
            guideDto.id = $scope.guide.id;
            guideDto.etd = $scope.guide.etd;
            guideDto.lockEDate = true;
            guideDto.shipmentType = $scope.shipmentType;
            $uibModal.open({
                templateUrl: 'app/entities/guide/amend-etd.html',
                controller: 'AmendEtdController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return guideDto;
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
                $translate.refresh();
            }, function() {});
        }
        $scope.setBillNull = function(rate) {
            if (!rate.billNum && !rate.mcNum) {
                return;
            }
            if($rootScope.HAVEAUTH('DNLOCKBILL') && rate.dnNum){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if ($scope.guide.isClosed && $rootScope.HAVENOAUTH('AU_FEE_FORCED_MODIFICATION')) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            OpsBill.checkBillAu(rate, function(result){
                if (result.au) {
                    $rootScope.FORBIDDEN_BY_ALERT(result.au);
                }
                else {
                    setBillNullConfirm(rate);
                }
            })
        }

        function setBillNullConfirm(rate) {
            layer.confirm($translate.instant('autopsApp.guide.setBillNullAlert'), {
                title: $translate.instant('global.messageInfo'),
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                var billNumCopy = rate.billNum;
                var mcNumCopy = rate.mcNum;
                angular.forEach($scope.guide.guideRates, function(data) {
                    if($rootScope.HAVEAUTH('DNLOCKBILL') && data.dnNum){
                    }
                    else {
                        if (data.billNum == billNumCopy) {
                            data.billNum = null;
                        }
                        if (data.mcNum == mcNumCopy) {
                            data.mcNum = null;
                        }
                    }
                })
                $translate.refresh();
                layer.close(index);
            }, function() {});
        }

        $scope.useTemplate = function() {
            if ($scope.guide.isClosed || $scope.guide.ratesLocked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            entity.guide = $scope.guide;
            entity.shipmentType = $scope.shipmentType;
            $uibModal.open({
                templateUrl: 'app/entities/opsTemplate/opsTemplate-choose.html',
                controller: 'OpsTemplateChooseController',
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                updateTotal();
            }, function() {
                updateTotal();
            });
        };

        var filterHidden = function(ratesCopy) {
            if (!ratesCopy || ratesCopy.length == 0) {
                return;
            }
            for (var i = ratesCopy.length - 1; i >= 0; i--) {
                if (isHiddenRate(ratesCopy[i])) {
                    ratesCopy.splice(i, 1);
                    continue;
                }
                ratesCopy[i].priceDec = ratesCopy[i].price;
                ratesCopy[i].countDec = ratesCopy[i].count;
                ratesCopy[i].totalDec = ratesCopy[i].total;
                ratesCopy[i].orgTotal = ratesCopy[i].total;
            }
        }

        $scope.saveAsRatesTempalte = function() {
            if(!$scope.guide.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!$scope.guide.guideRates || $scope.guide.guideRates.length == 0) {
                layer.msg($translate.instant('autopsApp.guide.home.no_prices'));
                return;
            }
            var ratesCopy = angular.copy($scope.guide.guideRates);
            filterHidden(ratesCopy);
            $uibModal.open({
                templateUrl: 'app/entities/guide/template-rates.html',
                controller: 'TemplateRatesController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return ratesCopy;
                    }]
                }
            }).result.then(function(result) {
                $translate.refresh();
            }, function() {});
        }

        $scope.exchangeRate = function() {
            OpsExchangeRate.getEffectiveExchangeRates({
                date: DateUtils.convertLocalDateToServer($scope.guide.etd)
            }, function(result) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-exchange-rate/ops-exchange-rates-dialog.html',
                    controller: 'OpsExchangeRatesDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: [function() {
                            return result;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('opsExchangeRate');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(results) {

                }, function() {

                });
            })
        }

        $scope.rateUnitChange = function(rate) {
            if(units.indexOf(rate.unit) === -1){
                rate.autoUpdate = false;
            }
            // if(rate.unit != "SHIPMENT"){
            //     rate.refType = null;
            // }
            if(rate.unit == "CTNTYPE VAR" && $scope.guide.booking && $scope.guide.guideFclCtns){
                DataUtils.ctnTypeVarInput(rate, null, $scope.guide, $scope.shipmentType);
            }
            else {
                rate.count = DataUtils.round(DataUtils.getUnitCount(rate, $scope.shipmentType, $scope.guide), 4);
                $scope.amountChange(rate);
            }
        }

        $scope.updateRateCount = function() {
            if ($scope.guide.ratesLocked) {
                return;
            }
            angular.forEach($scope.guide.guideRates, function(rate) {
                if (!rate.locked && !rate.isHidden && !rate.sharedOn && !rate.billNum && !rate.mcNum 
                    && (!rate.refType || (rate.autoUpdate && rate.refType.indexOf("single_bill") !== 0))) {
                    rate.count = DataUtils.round(DataUtils.getUnitCount(rate, $scope.shipmentType, $scope.guide), 4);
                    if (!rate.price) {
                        rate.price = 0;
                    }
                    rate.total = rate.count * rate.price;
                    if (rate.days) {
                        rate.total = rate.total * rate.days;
                    }
                    rate.total = DataUtils.round(rate.total, 2);
                    rate.payCurrency = rate.currency;
                    rate.payExchange = 1.00;
                    rate.payAmount = DataUtils.round(rate.total, 2);
                }
            })
            updateTotal();
        };


        $scope.auditGuide = function(guide) {
            entity.guide = angular.copy(guide);
            entity.isFin = false;
            entity.exchangeRates = exchangeRates;
            $rootScope.VENDER_AUDIT_POP(entity);
        }
        $rootScope.LOAD_REPORT_TEMPLATES();

        $scope.dnMake = function(isDn, billNum) {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if ($rootScope.account.mustAudit && $scope.shipmentType == 'guide'
                && $scope.guide.checkStatus != 'approved') {
                layer.msg($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                return;
            }
            $scope.loadDefultPayer();
            var dnEntity = angular.copy($scope.guide);
            dnEntity.payers = $scope.payers;
            dnEntity.isDn = isDn;

            dnEntity.shpmtType = $scope.shipmentType;
            dnEntity.billNum = billNum;

            $uibModal.open({
                templateUrl: 'app/entities/guide/dn-dialog.html',
                controller: 'DnDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return dnEntity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsBill');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                if (result && result.emailType) {
                    if ((result.reportCode >= 200 && result.reportCode < 210) ||
                        (result.reportCode >= 410 && result.reportCode < 420) ||
                        (result.reportCode >= 730 && result.reportCode < 740) ||
                        (result.reportCode >= 850 && result.reportCode < 860)) {
                        dnSendEmail(result);
                    } else {
                        cnSendEmail(result);
                    }
                }
                loadGuide();
            }, function(result) {
                loadGuide();
            });
        }

        var dnSendEmail = function(result) {
            var email = {};
            email.attachments = [];
            var subjectNum = $scope.shipmentType == "wms" ? $scope.guide.warehousingNumber : $scope.guide.jobNum;
            if (!subjectNum) {
                if ($scope.guide.hblNum) {
                    subjectNum = $scope.guide.hblNum;
                } else if ($scope.guide.mblNum) {
                    subjectNum = $scope.guide.mblNum;
                } else {
                    subjectNum = $scope.guide.jobNum;
                }
            }
            if($scope.guide.soNum){
                subjectNum += "/ " + $scope.guide.soNum;
            }
            if($scope.shipmentType == "guide"){
                subjectNum += "/ " + $scope.guide.polName + " - " + $scope.guide.destName + "(" + $scope.guide.volumes + " By " + $scope.guide.carrier + "; ETD: "+ DateUtils.convertLocalDateToServer($scope.guide.etd) + ")";
            }
            email.subject = subjectNum + "--" + $translate.instant('entity.email.dnSendSubject');
            email.content = $translate.instant('entity.email.dnSendContent') + "\n";

            if($rootScope.ISTMS){
                email.subject = $scope.guide.shipperName + "/ " + $scope.guide.shiptoCode + "/ " + $scope.guide.pallets + "PLTS/ " + $scope.guide.jobNum + "/ " + result.billNo;
                email.content = "Your invoice-" + $scope.guide.jobNum + " for " + result.billAmtStr + " is attached. Please kindly review and remit payment at your earliest convenience. Please feel free to contact us if you have any question. Thank you for your business. We appreciate it very much.\n\n"
            }

            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = result.emailType;
            entity.code = null;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = result.venderId;
            if (result.report.length > 0) {
                entity.multiAttachments = result.report;
            }
            entity.isExpress = $scope.shipmentType == 'expressParcel'?true:null;
            entity.isWms = $scope.shipmentType == 'wms'?true:null;
            sendEmailPop(entity);
        }

        var cnSendEmail = function(result) {
            var email = {};
            email.attachments = [];
            var subjectNum = $scope.shipmentType == "wms" ? $scope.guide.warehousingNumber : $scope.guide.jobNum;
            if (!subjectNum) {
                if ($scope.guide.hblNum) {
                    subjectNum = $scope.guide.hblNum;
                } else if ($scope.guide.mblNum) {
                    subjectNum = $scope.guide.mblNum;
                } else {
                    subjectNum = $scope.guide.jobNum;
                }
            }
            email.subject = subjectNum + "--" + $translate.instant('entity.email.cnSendSubject');
            email.content = $translate.instant('entity.email.cnSendContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = result.emailType;
            entity.code = null;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = result.venderId;
            if (result.report.length > 0) {
                entity.fileResult = result.report[0];
            }
            entity.isExpress = $scope.shipmentType == 'expressParcel'?true:null;
            entity.isWms = $scope.shipmentType == 'wms'?true:null;

            sendEmailPop(entity);
        }

        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                if (entity.emailType == "dnSend") {
                    $scope.guide.status = "DnSent";
                    $scope.guide.booking.status = "DnSent";
                    $scope.guide.dnSentTime = new Date();
                }
                else if (entity.emailType == "cnSend") {
                }
                else {
                    $scope.guide = result;
                }
            }, function(result) {});
        }
        $scope.invoiceRequestShow = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };

            if($scope.shipmentType == 'guide'){
                // if ($scope.guide.companyId == 367 && !$scope.guide.ratesLocked) {
                //     layer.msg($translate.instant('autopsApp.guide.sts.rateNotLocked'));
                //     return;
                // }
                if (!$scope.guide.submited) {
                    layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                    return;
                }
                if ($rootScope.account.mustAudit && $scope.guide.checkStatus != 'approved') {
                    layer.msg($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                    return;
                }
            }
            if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                return;
            };
            var guideEntity = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/guide/invocie-request.html',
                controller: 'InvoiceRequestController',
                size: 'lg',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return guideEntity;
                    }]
                }
            }).result.then(function(result) {
                loadGuideAsyn();                              
            }, function() {
                loadGuide();
            });
        }
        var loadGuideAsyn = function(isYhsp) {
            layer.msg($translate.instant('global.dataSynchronization'), {shade: 0.3});
            setTimeout(function () {
              loadGuide(isYhsp);
            }, 2000);
        }

        $scope.paymentRequestShow = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if($scope.shipmentType == 'guide'){
                // if ($scope.guide.companyId == 367 && !$scope.guide.ratesLocked) {
                //     layer.msg($translate.instant('autopsApp.guide.sts.rateNotLocked'));
                //     return;
                // }
                if (!$scope.guide.submited) {
                    layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                    return;
                }
                if ($rootScope.account.mustAudit && $scope.guide.checkStatus != 'approved') {
                    layer.msg($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                    return;
                }
            }
            if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                return;
            };
            var guideEntity = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/guide/payment-request.html',
                controller: 'PaymentRequestController',
                size: 'lg',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return guideEntity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountPayment');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                loadGuideAsyn();
            }, function() {
                loadGuide();
            });
        }
        $scope.rateLocked = function(rate) {
            if(rate.locked || rate.audited || rate.actual){
                return true;
            }
            if($scope.jobRateLocked()){
                return true;
            }
            if(rate.isPay && $scope.guide.expLock && $rootScope.HAVENOAUTH('AU_FEE_FORCED_MODIFICATION')){
                return true;
            }
            if(!rate.isPay && $scope.guide.revLock && $rootScope.HAVENOAUTH('AU_FEE_FORCED_MODIFICATION')){
                return true;
            }
            return false;
        }

        var invoiceSendEmail = function(result) {
            var email = {};
            email.attachments = [];

            email.subject = result.dnNum + "--" + $translate.instant('autopsApp.guide.proformaInvoice');
            email.content = $translate.instant('entity.email.proformaInvoice', {dnNum: result.dnNum}) + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = result.emailType;
            entity.code = null;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = result.venderId;
            if (result.report.length > 0) {
                entity.fileResult = result.report[0];
            }
            entity.isExpress = $scope.shipmentType == 'expressParcel'?true:null;
            entity.isWms = $scope.shipmentType == 'wms'?true:null;

            sendEmailPop(entity);
        }

        $scope.proformaInvoice = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var entity = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/guide/proforma-invoice-dialog.html',
                controller: 'ProformaInvoiceDialogController',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                if (result.emailType) {
                    invoiceSendEmail(result);
                }
            }, function(result) {

            });
        }
        $scope.getWmsWorkLogsByVenderId = function() {
            if (!$scope.guide.shipperId && !$scope.guide.venderId) {
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            $scope.guide.typeTemp = $scope.shipmentType;
            $uibModal.open({
                templateUrl: 'app/entities/wms-work-log/work-logs-list-dialog.html',
                controller: 'WmsWorkLogListController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return $scope.guide;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsWorkLog');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                $scope.guide.guideRates = result.guideRates;
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        $scope.getWmsCharges = function() {
            if (!$scope.guide.shipperId && !$scope.guide.venderId) {
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            $scope.guide.typeTemp = $scope.shipmentType;
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-charges.html',
                controller: 'WmsInChargesController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return $scope.guide;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('wmsCargo');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                $scope.guide.guideRates = result.guideRates;
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        $scope.previewReport = function(code) {
            if ($rootScope.HAVENOAUTH('FUN_VIEW_PAYER')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYER');
                return;
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEW_PAYEE')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYEE');
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);
            filterHidden(guideReportDTO.guideRates);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {

            });
        }
        $scope.applyRelease = function() {
            if (!$scope.guide.submited) {
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            if(!$scope.guide.finId){
                layer.msg($translate.instant('autopsApp.guide.home.finNullAlert'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            if ($scope.guide.hblReleasedTime && $scope.guide.isApplied) {
                layer.msg($translate.instant('autopsApp.guide.home.blrelased'));
                $scope.guide.isApplied = !$scope.guide.isApplied;
                return;
            }
            $scope.isSaving = true;
            Guide.applyRelease({
                guideId: $scope.guide.id,
                isApplied: $scope.guide.isApplied
            }, function(result, headers) {
                loadGuide();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }


        $scope.salesChecked = function() {
            $scope.isSaving = true;
            Guide.salesChecked({
                guideId: $scope.guide.id,
                salesChecked: $scope.guide.salesChecked
            }, function(result) {
                $scope.isSaving = false;
                loadGuide();
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(result) {
                $scope.isSaving = false;
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            });
        };

        $scope.ratesSubmit = function(isPay) {
            if (!isPay) {
                if($rootScope.HAVENOAUTH('FUN_VIEW_PAYER')){
                    $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYER');
                    return;
                }
                if($rootScope.HAVENOAUTH('AU_VIEW_REV')){
                    $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_REV');
                    return;
                }
            }
            if (isPay || isPay == null) {
                if($rootScope.HAVENOAUTH('FUN_VIEW_PAYEE')){
                    $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYEE');
                    return;
                }
                if($rootScope.HAVENOAUTH('AU_VIEW_EXP')){
                    $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_EXP');
                    return;
                }
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEW_ONE_HIDDEN')) {
                for(var i = $scope.guide.guideRates.length -1; i >= 0; i--){
                    if($scope.guide.guideRates[i].hiddenRate
                        && $scope.guide.guideRates[i].venderId
                        && $scope.guide.guideRates[i].payAmount
                        && $scope.guide.guideRates[i].payCurrency
                        && $scope.guide.guideRates[i].payer
                        && !$scope.guide.guideRates[i].dnNum){
                        $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_ONE_HIDDEN');
                        return;
                    }
                }
            }

            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                return;
            };
            if($scope.shipmentType == 'guide'){
                // if ($scope.guide.companyId == 367 && !$scope.guide.ratesLocked) {
                //     layer.msg($translate.instant('autopsApp.guide.sts.rateNotLocked'));
                //     return;
                // }
                if (!$scope.guide.submited) {
                    layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                    return;
                }
                if ($rootScope.account.mustAudit && $scope.guide.checkStatus != 'approved') {
                    layer.msg($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                    return;
                }
            }
            var entity = angular.copy($scope.guide);
            entity.isGuide = true;
            entity.isPay = isPay;
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/batchRatesSubmit.html',
                controller: 'BatchRatesSubmitController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                loadGuideAsyn($rootScope.IS_YHSP());
            }, function(result) {
            });
        }

        $scope.hidenSelling = function() {
            if ($scope.guide && !$scope.guide.hidenSelling) {
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return true;
            }
            return $rootScope.account.authorities.indexOf("FUN_VIEWSELLING") == -1;
        };

        $scope.hideCost = function() {
            if ($scope.guide && !$scope.guide.hidenCost) {
                return false;
            }
            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                return true;
            }
            return $rootScope.account.authorities.indexOf("FUN_VIEWCOST") == -1;
        };

        $scope.splitSelling = function() {
            if ($scope.guide.isClosed || $scope.guide.rateLocked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!isOp()) {
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/guide/share-selling-dialog.html',
                controller: 'ShareSellingDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return $scope.guide;
                    }]
                }
            }).result.then(function(result) {

            }, function(result) {

            });
        };

        $scope.combineCost = function() {
            if ($rootScope.HAVENOAUTH('AU_CHARGES_SHARE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_CHARGES_SHARE');
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $scope.isSaving = true;
            ExpressParcel.combineCost({
                masterId: $scope.guide.id,
                isFms: $scope.shipmentType == "guide"
            }, loadGuide, $rootScope.ALERT_ERROR);
        }

        $scope.printApplication = function(rate) {
            if (!rate.dnNum) {
                return;
            };
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };

            if(rate.dnNum.indexOf("DN") == 0){
                $scope.printInvoice(rate);
                return;
            }
            if($rootScope.HAVENOAUTH("FUN_VIEW_PAYEE")){
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYEE');
                return;
            }
            if (rate.dnNum.indexOf("CS") == 0) {
                if(rate.wmsCargoId){
                    showCargoShareList(rate);
                }
                else if(rate.dnNum.indexOf("CSM") == 0){
                    showCombineList(rate);
                }
                else {
                    showShareList(rate);
                }
            } else {
                $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountPayment/accountPayment-delete-dialog.html',
                    controller: 'AccountPaymentDeleteController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xl',
                    resolve: {
                        entity: [function() {
                            return AccountPayment.getByDnNum({
                                companyId: $rootScope.account.companyId,
                                dnNum: rate.dnNum
                            }).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('accountPayment');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(result) {
                    loadGuide();
                }, function() {
                    // $state.go('^');
                });
            }
        };

        $scope.printInvoice = function(rate) {
            if(rate.dnNum.indexOf("CS") == 0){
                if(rate.wmsCargoId){
                    showCargoShareList(rate);
                }
                else if(rate.dnNum.indexOf("CSM") == 0){
                    showCombineList(rate);
                }
                else {
                    showShareList(rate);
                }
                return;
            }
            if(rate.dnNum.indexOf("CN") == 0){
                $scope.printApplication(rate);
                return;
            }
            if($rootScope.HAVENOAUTH("FUN_VIEW_PAYER")){
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYER');
                return;
            }
            if (!rate.dnNum) {
                return;
            };
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };

            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountInvoice/accountInvoice-delete-dialog.html',
                controller: 'AccountInvoiceDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return AccountInvoice.getByDnNum({
                            companyId: $rootScope.account.companyId,
                            dnNum: rate.dnNum}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
            }, function() {
                // $state.go('^');
            });
        };

        var showCombineList = function(rate) {
            if (!isOp()) {
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/guide/combine-cost-dialog.html',
                controller: 'CombineCostDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return rate;
                    }]
                }
            }).result.then(function(result) {
                if (result) {
                    loadGuide();
                }
            }, function(result) {

            });
        };
        var showShareList = function(rate) {
            if (!isOp()) {
                layer.msg($translate.instant('global.opDocAuRequired'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/guide/share-list-dialog.html',
                controller: 'ShareListDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return rate;
                    }]
                }
            }).result.then(function(result) {
                if (result) {
                    loadGuide();
                }
            }, function(result) {

            });
        };
        $scope.viewHblsGP = function() {
            ExpressParcel.getHblsProfit({
                companyId: $scope.guide.companyId,
                masterId: $scope.guide.id,
                isFms: $scope.shipmentType == "guide"
            }, function(result){
                var entity = {};
                entity.content = result;
                $uibModal.open({
                    templateUrl: 'app/entities/stats/profit/job-list-dialog.html',
                    controller: 'JobListDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return entity;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('stats');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                }, function() {
                });
            })
        }

        KEYBOARD = "guideRatesDialog";
        var saved = false;
        $(document).keydown(function(event) {
            if( KEYBOARD != "guideRatesDialog"){
                return;
            }
            else if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                if (saved){
                    return;
                }
                else {
                    saved = true;
                    $scope.saveRates();
                }
            }
            else if (event.keyCode == 107 && event.ctrlKey) {
                event.preventDefault();
                var aId = document.activeElement.id;
                if(!aId || isNaN(aId)){  return;}
                var type = aId.substring(0, 1);
                if(type == 3){
                    var rowIndex = aId.substring(3)-0;
                    var index = -1;
                    for(var i = 0; i <$scope.guide.guideRates.length; i++){
                        if(!$scope.guide.guideRates[i].isPay){
                            index++;
                        }
                        if(rowIndex == index){
                            rowIndex = i;
                            break;
                        }
                    }
                    $scope.addOne(false, $scope.guide.guideRates[rowIndex]);
                }
                else if (type == 4){
                    var rowIndex = aId.substring(3)-0;
                    var index = -1;
                    for(var i = 0; i <$scope.guide.guideRates.length; i++){
                        if($scope.guide.guideRates[i].isPay){
                            index++;
                        }
                        if(rowIndex == index){
                            rowIndex = i;
                            break;
                        }
                    }
                    $scope.addOne(true, $scope.guide.guideRates[rowIndex]);
                }
            }
            else if ((event.keyCode == 109 || event.keyCode == 68 || event.keyCode == 46) && event.ctrlKey) {
                event.preventDefault();
                var aId = document.activeElement.id;
                if(!aId || isNaN(aId)){  return;}
                var type = aId.substring(0, 1);
                var id = aId.substring(3);
                if(type == 3){
                    var rowIndex = aId.substring(3)-0;
                    var index = -1;
                    for(var i = 0; i <$scope.guide.guideRates.length; i++){
                        if(!$scope.guide.guideRates[i].isPay){
                            index++;
                        }
                        if(rowIndex == index){
                            rowIndex = i;
                            break;
                        }
                    }
                    $scope.deleteRate($scope.guide.guideRates[rowIndex], true);
                }
                else if (type == 4){
                    var rowIndex = aId.substring(3)-0;
                    var index = -1;
                    for(var i = 0; i <$scope.guide.guideRates.length; i++){
                        if($scope.guide.guideRates[i].isPay){
                            index++;
                        }
                        if(rowIndex == index){
                            rowIndex = i;
                            break;
                        }
                    }
                    $scope.deleteRate($scope.guide.guideRates[rowIndex], true);
                }
            }
        });
        $scope.$on('modal.closing', function(event, reason, closed){
            if(reason == "escape key press"){
                event.preventDefault(); //You can use this to prevent the modal from closing
                $scope.goBack();
            }
        });
        $scope.loadByJobNum = function() {
            exchangeRates = [];
            $scope.isSaving = true;
            if ($scope.shipmentType == 'guide') {
                Guide.getGuideAndRatesByJobNum({
                    companyId: $rootScope.account.companyId,
                    jobNum: $scope.jobNumCopy
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    initVirtual();
                    $scope.isSaving = false;
                    $scope.jobNumCopy = result.jobNum;
                    $scope.files = [];
                }, onloadFailed)
            } else if ($scope.shipmentType == 'wms') {
                WmsIn.getGuideRatesByWarehousingNumber({
                    companyId: $rootScope.account.companyId,
                    warehousingNumber: $scope.jobNumCopy
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    initVirtual();
                    $scope.isSaving = false;
                    $scope.jobNumCopy = result.warehousingNumber;
                    $scope.files = [];
                }, onloadFailed)
            } else {
                ExpressParcel.getGuideAndRatesByJobNum({
                    companyId: $rootScope.account.companyId,
                    jobNum: $scope.jobNumCopy
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    initVirtual();
                    $scope.isSaving = false;
                    $scope.jobNumCopy = result.jobNum;
                    $scope.files = [];
                }, onloadFailed)
            }
        }

        var onloadFailed = function(result) {
            layer.msg($translate.instant('global.loadFailed'));
        };
        function getCurrentOneIdx() {
            var index = 0;
            for (var i = 0; i < $scope.guidesList.length; i++) {
                if ($scope.guidesList[i].id) {
                    if($scope.guidesList[i].id == $scope.guide.id){
                        index = i;
                        break;
                    }
                    continue;
                }
                if ($scope.shipmentType == 'guide' && $scope.guidesList[i].guideId == $scope.guide.id) {
                    index = i;
                    break;
                }
                if ($scope.shipmentType == 'expressParcel' 
                    && ($scope.guidesList[i].parcelId == $scope.guide.id || $scope.guidesList[i].expressParcelId == $scope.guide.id)) {
                    index = i;
                    break;
                }
                if ($scope.shipmentType == 'wms' && $scope.guidesList[i].wmsInId == $scope.guide.id) {
                    index = i;
                    break;
                }
            }
            return index;
        }
        function loadNextOneStats(nextOne){
            if (nextOne.guideId) {
                $scope.shipmentType = 'guide';
                Guide.getGuideAndRatesByJobNum({
                    companyId: $rootScope.account.companyId,
                    id: nextOne.guideId
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    initVirtual();
                    $scope.isSaving = false;
                    $scope.jobNumCopy = result.jobNum;
                }, onloadFailed)
            } else if (nextOne.wmsInId) {
                $scope.shipmentType = 'wms';
                WmsIn.getGuideRatesByWarehousingNumber({
                    companyId: $rootScope.account.companyId,
                    id: nextOne.wmsInId
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    initVirtual();
                    $scope.isSaving = false;
                    $scope.jobNumCopy = result.warehousingNumber;
                }, onloadFailed);
            } else {
                $scope.shipmentType = 'expressParcel';
                ExpressParcel.getGuideAndRatesByJobNum({
                    companyId: $rootScope.account.companyId,
                    id: nextOne.expressParcelId?nextOne.expressParcelId:nextOne.parcelId
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    initVirtual();
                    $scope.isSaving = false;
                    $scope.jobNumCopy = result.jobNum;
                }, onloadFailed);
            }
        }

        function loadNextOne(nextOne){
            if(!nextOne.id){
                loadNextOneStats(nextOne);    
                return;
            } 
            if ($scope.shipmentType == 'guide') {
                Guide.getGuideAndRatesByJobNum({
                    companyId: $rootScope.account.companyId,
                    id: nextOne.id
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    initVirtual();
                    $scope.isSaving = false;
                    $scope.jobNumCopy = result.jobNum;
                }, onloadFailed)
            } else if ($scope.shipmentType == 'wms') {
                WmsIn.getGuideRatesByWarehousingNumber({
                    companyId: $rootScope.account.companyId,
                    id: nextOne.id
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    initVirtual();
                    $scope.isSaving = false;
                    $scope.jobNumCopy = result.warehousingNumber;
                }, onloadFailed);
            } else {
                ExpressParcel.getGuideAndRatesByJobNum({
                    companyId: $rootScope.account.companyId,
                    id: nextOne.id
                }, function(result) {
                    $scope.guide = result;
                    $scope.getExchangeRatesMap($scope.guide.etd);
                    initVirtual();
                    $scope.isSaving = false;
                    $scope.jobNumCopy = result.jobNum;
                }, onloadFailed);
            }
        }

        $scope.nextGuide = function() {
            if (!$scope.guidesList || $scope.guidesList.length == 0) {
                layer.msg($translate.instant('global.last_record_alert'));
                return;
            }
            var index = getCurrentOneIdx();
            var nextOne = $scope.guidesList[index + 1];
            if (!nextOne) {
                layer.msg($translate.instant('global.last_record_alert'));
                return;
            }
            loadNextOne(nextOne);
        }

        $scope.previousGuide = function() {
            if (!$scope.guidesList || $scope.guidesList.length == 0) {
                layer.msg($translate.instant('global.first_record_alert'));
                return;
            }
            var index = getCurrentOneIdx();   
            var nextOne = $scope.guidesList[index - 1];
            if (!nextOne) {
                layer.msg($translate.instant('global.first_record_alert'));
                return;
            }
            loadNextOne(nextOne);                       
        }                

        $scope.synVirtualRates = function() {
            GuideRates.synVirtualRates({
                type: $scope.shipmentType,
                id: $scope.guide.id
            }, function(result){
                if(result && result.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                angular.forEach(result, function(rate){
                    $scope.guide.guideRates.push(rate);
                })
            })
        }

        $scope.opsCosts = [];
        $scope.opsSellings = [];
        $scope.findPrices = function(isSelling) {
            if(!isSelling && $scope.opsCosts.length > 0){return;}
            if(isSelling && $scope.opsSellings.length > 0){return;}
            var shipmentType;
            if($scope.shipmentType == 'expressParcel'){
                if($rootScope.ISTMS){
                    shipmentType = "TMS";
                }
                else {
                    shipmentType = "PARCEL";
                }
            }
            else if ($scope.shipmentType == 'guide'){
                if($scope.guide.shipmentType == "FCL"){
                    shipmentType = "FCL";
                }
                else if($scope.guide.shipmentType == "LCL"){
                    shipmentType = "LCL";
                }
                else if($scope.guide.shipmentType == "AIR"){
                    shipmentType = "AIR";
                }
                else{
                    shipmentType = "FBA";
                }
            }
            if(!shipmentType){
                return;
            }
            OpsCost.findPrices({
                etd: DateUtils.convertLocalDateToServer($scope.guide.etd),
                isSelling: isSelling,
                shipmentType: shipmentType}, function(result){
                if(isSelling){
                    $scope.opsSellings = result;
                }
                else {
                    $scope.opsCosts = result;
                }
            })
        }

        $scope._onFmsPriceSelected = function(data, isSelling) {
            if($scope.guide.isClosed || $scope.guide.ratesLocked){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if(isSelling){
                DataUtils.updateGuideByProduct($scope.guide, data, true);
                $scope.updateRateByCost(false);
            }
            else {
                $scope.guide.costMath = data.identification;
                $scope.updateRateByCost(true);
            }
        }

        $scope._onPriceSelected = function(data, isSelling) {
            if($scope.guide.isClosed || $scope.guide.ratesLocked){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if(isSelling){
                $scope.guide.sellingId = data.id;
            }
            else {
                $scope.guide.costId = data.id;
            }
            getParcelRateByCost(!isSelling);
        }

        var getParcelRateByCost = function(isCost) {
            if((isCost && !$scope.guide.costId) || (!isCost && !$scope.guide.sellingId)){
                return;
            }
            ExpressParcel.refreshCharges({isPay:isCost}, $scope.guide, function(result) {
                $scope.guide = result;
                updateTotal();
            })
        }

        $scope.defualtReportCode = 200;
        $scope.getDefualtReportCode = function(isDn) {
            if(isDn){
                if($scope.shipmentType == 'expressParcel'){
                    $scope.defualtReportCode = 730;
                }
                else if($scope.shipmentType == 'wms'){
                    $scope.defualtReportCode = 850;
                }
                else if ($scope.guide.shipmentType == 'AIR' || $scope.guide.shipmentType == 'FBAAIR'){
                    $scope.defualtReportCode = 410;
                }
                else {
                    $scope.defualtReportCode = 200;
                }
            }
            else {
                if($scope.shipmentType == 'expressParcel'){
                    $scope.defualtReportCode = 740;
                }
                else if($scope.shipmentType == 'wms'){
                    $scope.defualtReportCode = 860;
                }
                else if ($scope.guide.shipmentType == 'AIR' || $scope.guide.shipmentType == 'FBAAIR'){
                    $scope.defualtReportCode = 420;
                }
                else {
                    $scope.defualtReportCode = 210;
                }
            }
        }
        $scope.fastSend = function(isDn, reportCode, allInOne) {
            var guideReportDTO = angular.copy($scope.guide);
            filterHidden(guideReportDTO.guideRates);
            var venderId = null;
            var payer = null;
            if(isDn){
                venderId = $scope.shipmentType == "wms" ? guideReportDTO.venderId : guideReportDTO.shipperId;
            }
            else {
                venderId = $scope.shipmentType == "wms" ? null : guideReportDTO.bookingToId;
            }

            if(!venderId){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }

            for(var i = guideReportDTO.guideRates.length -1; i >= 0; i--){
                if(guideReportDTO.guideRates[i].isHidden
                    || guideReportDTO.guideRates[i].venderId != venderId
                    || guideReportDTO.guideRates[i].payAmount == null
                    || !guideReportDTO.guideRates[i].payCurrency
                    || !guideReportDTO.guideRates[i].payer
                    || (guideReportDTO.guideRates[i].dnNum && guideReportDTO.guideRates[i].dnNum.indexOf("C") == 0 && !$rootScope.isYuyue(guideReportDTO.companyId))
                    || (isDn && !allInOne && guideReportDTO.guideRates[i].billNum && guideReportDTO.guideRates[i].billNum.indexOf("C") == 0 && !$rootScope.isYuyue(guideReportDTO.companyId))
                    || (!isDn && !allInOne && guideReportDTO.guideRates[i].billNum && guideReportDTO.guideRates[i].billNum.indexOf("D") == 0 && !$rootScope.isYuyue(guideReportDTO.companyId))){
                    guideReportDTO.guideRates.splice(i, 1);
                }
                else if(!payer){
                    payer = guideReportDTO.guideRates[i].payer;
                }
            }

            if(!guideReportDTO.guideRates || guideReportDTO.guideRates.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }

            angular.forEach($scope.guide.guideRates, function(rate) {
                if(rate.isPay && rate.payAmount > 0){
                    rate.showAmt = -rate.payAmount;
                }
                else {
                    rate.showAmt = rate.payAmount;
                }
            });

            $scope.isSaving = true;
            guideReportDTO.isEn = false;
            guideReportDTO.userBankInfo = "";
            guideReportDTO.issueDate = new Date();
            guideReportDTO.payer = payer;
            guideReportDTO.billNumStr = "";
            guideReportDTO.opType = 3;
            guideReportDTO.fileType = 1;
            guideReportDTO.reportType = reportCode;
            guideReportDTO.allInOne = allInOne;
            OpsReport.getMultiAttachments({
                reportTypes: guideReportDTO.reportType
            }, guideReportDTO, function(result) {
                $scope.isSaving = false;
                var resultMap = {};
                resultMap.reportCode = guideReportDTO.reportType;
                resultMap.emailType = isDn?"dnSend":"cnSend";
                resultMap.venderId = venderId;
                resultMap.billNo = "";
                resultMap.billAmtStr = "";
                resultMap.guide = guideReportDTO;
                resultMap.report = result;
                if (isDn) {
                    dnSendEmail(resultMap);
                } else {
                    cnSendEmail(resultMap);
                }
                loadGuide();
            })
        }
        function reloadFiles (file) {
            if (!angular.isDefined(file.filepath)) {
                return;
            }

            var exist = false;
            if ($scope.files.length > 0) {
                for (var i = 0; i < $scope.files.length; i++) {
                    if ($scope.files[i]['filename'] == file['filename']) {
                        $scope.files[i]['filepath'] = file['filepath'];
                        $scope.files[i]['filename'] = file['filename'];
                        $scope.files[i]['fileurl'] = file['fileurl'];
                        exist = true;
                        break;
                    }
                }
            }
            if (!exist) {
                var temp = new Object();
                temp['filepath'] = file['filepath'];
                temp['filename'] = file['filename'];
                temp['fileurl'] = file['fileurl'];
                temp['fileist'] = true;
                $scope.files.push(temp);
            }
        }
        $scope.uploadFiles = function(file) {
            if (!file || !$scope.guide.companyId) {
                return;
            }
            var fd = new FormData();
            var filePath = "REPORTS/GUIDE/" + $scope.guide.id + "/";

            if($scope.shipmentType == 'expressParcel'){
                filePath = "/" + "expressParcel/" + $scope.guide.id + "/";
            }
            else if ($scope.shipmentType == 'wms'){
                filePath = "/" + "wmsIn/" + $scope.guide.id + "/";
            }

            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $scope.guide.companyId);

            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        reloadFiles(data);
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };


        $scope.loadFiles = function() {
            if($scope.files && $scope.files.length > 0){
                return;
            }
            $scope.files = [];
            if ($scope.guide && $scope.guide.id) {
                var filePath = 'REPORTS/GUIDE/' + $scope.guide.id + '/';
                if($scope.shipmentType == "expressParcel"){
                    filePath = "/" + "expressParcel/" + $scope.guide.id + "/";
                }
                else if($scope.shipmentType == "wms"){
                    filePath = "/" + "wmsIn/" + $scope.guide.id + "/";
                }
                OpsFiles.getFilesList({
                    filepath: filePath,
                    companyId: $scope.guide.companyId
                },function (data){
                    if(data.length>0){
                        for(var i = 0 ; i < data.length; i++){
                            var temp  = new Object();
                            temp['filepath'] = data[i]['filepath'];
                            temp['filename'] = data[i]['filename'];
                            temp['fileurl'] = data[i]['fileurl'];
                            temp['fileist'] = true;
                            $scope.files.push(temp);
                        }
                    }
                });
            }
        }

        $scope.payerSet = [];
        $scope.subPayerSet = [];
        $scope.getPayerSet = function() {
            var venderIdSet = new Set();
            $scope.payerSet = [];
            angular.forEach($scope.guide.guideRates, function (rate) {
                if(rate.venderId && !venderIdSet.has(rate.venderId) && !rate.isPay){
                    venderIdSet.add(rate.venderId);
                    var payer = {};
                    payer.venderId = rate.venderId;
                    payer.payer = rate.payer;
                    $scope.payerSet.push(payer);
                }
            })
            if($rootScope.IS_MASTER($scope.guide)){
                ExpressParcel.getAllPayers({
                    isFms: $scope.shipmentType == "guide",
                    masterId: $scope.guide.id
                }, function(payers){
                    $scope.subPayerSet = payers;
                })
            }

        }
        $scope.chargeOrRefund = function(venderId, isCharge) {
            if(isCharge && $rootScope.HAVENOAUTH('AU_DEDUCT_FEE')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEDUCT_FEE');
                return;
            }
            if(!isCharge && $rootScope.HAVENOAUTH('AU_REFUND_FEE')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_REFUND_FEE');
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if(!$scope.guide.guideRates || $scope.guide.guideRates.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                return;
            };
            var ratesCopy = angular.copy($scope.guide.guideRates);
            for(var i = ratesCopy.length - 1; i >= 0; i--){
                if(!ratesCopy[i].venderId || ratesCopy[i].venderId != venderId){
                    ratesCopy.splice(i, 1);
                }
                else if(isCharge && ratesCopy[i].deductBillId){
                    ratesCopy.splice(i, 1);
                }
                else if(!isCharge && !ratesCopy[i].deductBillId){
                    ratesCopy.splice(i, 1);
                }
                else if(ratesCopy[i].isPay || !ratesCopy[i].companyId
                    || !ratesCopy[i].payAmount || !ratesCopy[i].payCurrency
                    || (ratesCopy[i].locked && isCharge)){
                    ratesCopy.splice(i, 1);
                }
            }

            if(ratesCopy.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }

            var entity = {};
            entity.guideRates = ratesCopy;
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/guideRates-charge-choose.html',
                controller: 'GuideRatesChargeChooseController',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                if(isCharge){
                    chargeOrRefund($translate.instant('autopsApp.guide.chargeDeductionAlert', {param: result.length}), result, isCharge, venderId);
                }else{
                    chargeOrRefund($translate.instant('autopsApp.guide.chargeRefundAlert', {param: result.length}), result, isCharge, venderId);
                }
            }, function() {});

            function chargeOrRefund(content, rates, isCharge, venderId) {
                layer.confirm(content, {
                    title: "Msg",
                    icon:3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    $scope.isSaving = true;
                    layer.load(1, { shade: 0.3 });
                    GuideRates.chargeOrRefund({
                        isCharge: isCharge,
                        venderId: venderId
                    }, rates, function(result){
                        layer.closeAll();
                        $scope.isSaving = false;                        
                        loadGuide();
                        if(result){
                            $rootScope.OPS_ALERT($translate.instant('global.messages.depositeBal', {currency: $rootScope.account.baseCurrency, balance: result.deposite}), {icon:0});
                        }
                        else{
                            layer.msg($translate.instant('global.messages.submitted'));
                        }
                    }, function(result){
                        layer.closeAll();
                        $scope.isSaving = false;
                        if (result.status == 403) {
                            $rootScope.OPS_ALERT($translate.instant('global.messages.noDeposite'), {icon:0});
                        } else {
                            layer.msg($translate.instant('global.messages.submittedfailed'));
                        }
                    })
                    layer.close(index);
                }, function() {});
            }
        }



        $scope.costRequest = function() {
            if ($scope.guide.isClosed || $scope.guide.ratesLocked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            var entity = angular.copy($scope.guide.booking);
            entity.shipmentType = $scope.guide.shipmentType;
            entity.isNew = false;
            entity.isCostFinder = true;
            $uibModal.open({
                templateUrl: 'app/entities/ops-cost/ops-cost-detail.html',
                controller: 'OpsCostDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                $scope.guide.costMath = result.identification;
                $scope.updateRateByCost(true);
            }, function() {

            });
        }

        $scope.sellingRequest = function() {
            if ($scope.guide.isClosed || $scope.guide.ratesLocked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if($scope.guide.shipmentType !== "FCL"){
                $rootScope.FBA_TRIAL_COST_POP($scope.guide);
                return;
            }
            var entity = angular.copy($scope.guide.booking);
            entity.shipmentType = $scope.guide.shipmentType;
            entity.isNew = false;
            $uibModal.open({
                templateUrl: 'app/entities/ops-cost/ops-cost-detail.html',
                controller: 'OpsCostDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                $scope.guide.productName = result.productName;
                $scope.guide.sellingMath = result.identification;
                $scope.guide.deliveryWay = result.deliveryWayCode;
                $scope.updateRateByCost(false);
            }, function() {
            });
        }

        $scope.updateRateByCost = function(isCost) {
            // if((isCost && !$scope.guide.costId) || (false == isCost && !$scope.guide.booking.sellingId)){
            //     return;
            // }
            if(!$scope.guide.productName && $rootScope.HAVENOAUTH('ORDER_BY_CHANNEL')){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.productNameRequired'));
                return;
            }
            layer.load(1, {shade: 0.3});
            Guide.refreshCharges({isPay:isCost}, $scope.guide, function(result) {
                $scope.guide = result;
                updateTotal();
                layer.closeAll();
            }, $rootScope.ALERT_ERROR);
        }

        var units = Dictionary.autoPriceUnits;

        $scope.autoUpdateChange = function(rate) {
            if(!rate.refType && units.indexOf(rate.unit) === -1){
                layer.msg($translate.instant('autopsApp.booking.bookingPrice.autoUpdateAlert'));
                rate.autoUpdate = false;
            }
        }

        $scope.shareCargoCostDialog = function(isPay) {
            if (!$rootScope.HAVEAUTH('WMS_OVERSEAS')) {
                layer.msg($translate.instant('global.moduleNotLoad'));
                return;
            };
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var shareMCostDTO = {};
            shareMCostDTO.isPay = isPay;
            shareMCostDTO.guideRates = angular.copy($scope.guide.guideRates);
            if ($scope.shipmentType == 'guide') {
                shareMCostDTO.guide = angular.copy($scope.guide);
            } else if ($scope.shipmentType == 'wms') {
                shareMCostDTO.wmsIn = angular.copy($scope.guide);
            } else {
                shareMCostDTO.expressParcel = angular.copy($scope.guide);
            }
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/share-cargo-cost-dialog.html',
                controller: 'ShareCargoCostDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return shareMCostDTO;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
                loadGuide();
            }, function(result) {

            });
        };

        var showCargoShareList = function(rate) {
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/share-cargo-list-dialog.html',
                controller: 'ShareCargoListDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return rate;
                    }]
                }
            }).result.then(function(result) {
                if (result) {
                    loadGuide();
                }
            }, function(result) {

            });
        };

        $scope.setIsClosed = function() {
            if ($scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $scope.guide.isClosed = !$scope.guide.isClosed;
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                $scope.guide.isClosed = !$scope.guide.isClosed;
                return;
            };
            guideCopy.isClosed = !guideCopy.isClosed;
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                $scope.guide.isClosed = !$scope.guide.isClosed;
                guideCopy.isClosed = !guideCopy.isClosed;
                return;
            };
            if (!$scope.guide.isClosed) {
                if ($rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                    $scope.guide.isClosed = !$scope.guide.isClosed;
                    $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                    return;
                }
                $scope.guide.isClosed = false;
                $scope.guide.status = "Restored";
            } else {
                $scope.guide.isClosed = true;
                $scope.guide.status = "isClosed";
            }
            $scope.isSaving = true;
            if($scope.shipmentType == 'guide'){
                Guide.setIsClosed({id: $scope.guide.id}, function(result) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.messages.submitted'));
                    if(result.masterId == 0){
                        layer.confirm($translate.instant('autopsApp.guide.lockSubGuidesAllTips'), {
                            icon: 3,
                            btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                            }, function(index) {
                                layer.msg($translate.instant('global.isSavingMsg'), {
                                  shade: 0.3,
                                  time: 200000
                                });
                                Guide.lockSubGuidesAll({
                                    masterId: result.id,
                                    bValue: result.isClosed,
                                    type: "guide"
                                }, function(result) {
                                    layer.closeAll();
                                    layer.msg($translate.instant('global.messages.submitted'));
                                    loadGuide();
                                })
                                layer.close(index);
                            }, function() {
                                loadGuide();
                        });
                    }
                    else {
                        loadGuide();
                    }
                }, onSaveError);
            }
            else if($scope.shipmentType == 'wms'){
                WmsIn.setIsClosed({id: $scope.guide.id}, function(result) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.messages.submitted'));
                    loadGuide();
                }, onSaveError);
            }
            else {
                ExpressParcel.setIsClosed({id:$scope.guide.id}, function(result) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.messages.submitted'));
                    loadGuide();
                }, onSaveError);
            }


        }

        $scope.lockRateOne = function() {
            if ($scope.guide.ratesLocked && $rootScope.account.authorities.indexOf("AU_RATE_LOCK") === -1) {
                $scope.guide.ratesLocked = !$scope.guide.ratesLocked;
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_LOCK');
                return;
            }

            if (!$scope.guide.ratesLocked && $rootScope.account.authorities.indexOf("AU_RATE_UNLOCK") === -1) {
                $scope.guide.ratesLocked = !$scope.guide.ratesLocked;
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_UNLOCK');
                return;
            }

            $scope.isSaving = true;
            if($scope.shipmentType == 'guide'){
                Guide.lockRateOne({
                    guideId: $scope.guide.id,
                    ratesLocked: $scope.guide.ratesLocked
                }, function(result) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.messages.submitted'));
                    if(result.masterId == 0){
                        layer.confirm($translate.instant('autopsApp.guide.lockSubGuidesAllTips'), {
                            btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                            }, function(index) {
                                layer.msg($translate.instant('global.isSavingMsg'), {
                                  shade: 0.3,
                                  time: 200000
                                });
                                Guide.lockSubGuidesAll({
                                    masterId: result.id,
                                    bValue: result.ratesLocked,
                                    type: "rate"
                                }, function(result) {
                                    layer.closeAll();
                                    layer.msg($translate.instant('global.messages.submitted'));
                                    loadGuide();
                                })
                                layer.close(index);
                            }, function() {
                                loadGuide();
                        });
                    }
                    else {
                        loadGuide();
                    }
                }, function(result) {
                    $scope.isSaving = false;
                    layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                        title: $translate.instant('global.messageInfo'),
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        loadGuide();
                        layer.close(index);
                    }, function() {});
                });
            }
            else {
                ExpressParcel.lockRateOne({
                    parcelId: $scope.guide.id,
                    ratesLocked: $scope.guide.ratesLocked
                }, function(result) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.messages.submitted'));
                    loadGuide();
                }, function(result) {
                    $scope.isSaving = false;
                    layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                        title: $translate.instant('global.messageInfo'),
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        loadGuide();
                        layer.close(index);
                    }, function() {});
                });
            }            
        };

        $scope.addAutoWmsCharges = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if ($scope.guide.isClosed || $scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            WmsIn.addAutoWmsCharges($scope.guide, function(result) {
                loadGuide();
            }, function(error){
                if(error.status == 400){
                    layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                }
                else if(error.status == 404){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                }
            })
        }


        $scope.getSubtrackingCharges = function(rate) {
            GuideRates.getSubtrackingCharges({
                rateId: rate.id,
                guideId: $scope.shipmentType == 'guide'?$scope.guide.id:null,
                parcelId: $scope.shipmentType == 'expressParcel'?$scope.guide.id:null
            }, function(result) {
                $uibModal.open({
                    templateUrl: 'app/entities/sub-tracking/sub-trackings.html',
                    controller: 'SubTrackingController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: [function() {
                            return result;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('subTracking');
                            $translatePartialLoader.addPart('autoCheck');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(result) {
                }, function(result) {

                });
            })
        }

        $scope.getHouseGuidesRates = function() {
            if($scope.guide.masterId != 0){
                $rootScope.OPS_ALERT('该业务单不是主单！');
                return;
            }
            GuideRates.getHouseGuidesRates({
                masterId: $scope.guide.id
            }, function(result){
                if(result && result.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                angular.forEach(result, function(rate){
                    $scope.guide.guideRates.push(rate);
                })
            })
        }
        $scope.isReadOnly = function(rate) {
            if(rate.billNum || rate.mcNum){
                return true;
            }
            if(rate.id && $rootScope.HAVEAUTH('FB_EDIT_OTH_RATE')){
                var usrName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                if(usrName != rate.inputBy){
                    return true;
                }
            }
            if(rate.sharedOn && $rootScope.HAVEAUTH("SHARE_LOCK")){
                return true;
            }            
            return false;
        }
        $scope.isHiddenRate = function(rate) {
            if(isHiddenRate(rate)){
                return true;
            }
            if(rate.isPay){
                if($scope.hideShareChargesExp && rate.dnNum && rate.dnNum.indexOf("CS") == 0){
                    return true;
                }
                if($scope.hideHedgingChargesExp){
                    if(rate.isHidden){
                        return true;
                    }
                }
            }
            else {
                if($scope.hideShareChargesRev && rate.dnNum && rate.dnNum.indexOf("CS") == 0){
                    return true;
                }
                if($scope.hideHedgingChargesRev){
                    if(rate.isHidden){
                        return true;
                    }
                }
            }
            return false;
        }

        $scope.checkItem = function(rate) {
            for(var i = 0; i <$scope.guide.guideRates.length; i++){
                if(rate.isPay == $scope.guide.guideRates[i].isPay
                    && rate.item == $scope.guide.guideRates[i].item
                    && rate.payAmount == $scope.guide.guideRates[i].payAmount
                    && rate.id != $scope.guide.guideRates[i].id){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.itemExistAlert'));
                    break;
                }
            }
        }

        $scope.splitWriteOffCode = function(rate) {
            if(!rate.writeOffCode){
                return;
            }
            if(rate.writeOffCode.indexOf(" ") == -1){
                $scope.viewWriteOff(rate.writeOffCode, rate.id);
                return;
            }
            rate.writeOffCodes = rate.writeOffCode.split(" ");
        }
        $scope.getWriteOffRatesByRatesId = function(rate) {
            if(!rate.id){
                return;
            }
            rate.writeOffRates = null;
            GuideRates.getWriteOffRatesByRatesId({rateId: rate.id}, function(result){
                if(result && result.length == 1){
                    $scope.viewWriteOffByRate(result[0].writeOffId);
                }
                else {
                    rate.writeOffRates = result;
                }

            })
        }

        $scope.viewWriteOffByRate = function(writeOffId) {
            if(!writeOffId){
                return;
            }
            if($rootScope.HAVENOAUTH('ROLE_ACCOUNT') && $rootScope.HAVENOAUTH('ROLE_CASHIER')){
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/write-off/write-off-dialog.html',
                controller: 'WriteOffDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: ['WriteOff', function(WriteOff) {
                        return WriteOff.get({id: writeOffId}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('writeOff');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }



        $scope.viewWriteOff = function(writeOffCode, rateId) {
            if(!writeOffCode){
                return;
            }
            if($rootScope.HAVENOAUTH('ROLE_ACCOUNT') && $rootScope.HAVENOAUTH('ROLE_CASHIER')){
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/write-off/write-off-dialog.html',
                controller: 'WriteOffDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: ['WriteOff', 'WinAES', function(WriteOff, WinAES) {
                        return WriteOff.getWriteOffByRef({
                            rateId: rateId,
                            companyId : $scope.guide.companyId,
                            writeOffCode: writeOffCode
                        }).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('writeOff');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }


        $scope.dayAutoCharge = function() {
            WmsIn.dayAutoCharge($scope.guide, function(result){
                loadGuide();
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        }

        $scope.writeBackWmsChargesToGuide = function(payerMap) {
            var content = $translate.instant('autopsApp.guide.writeBackWmsChargesToGuideAlert') + "-" + payerMap.payer;
            layer.confirm(content, {
                title: "Msg",
                icon:3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                GuideRates.writeBackWmsChargesToGuide({
                    venderId: payerMap.venderId
                }, $scope.guide, function(result){
                    layer.msg($translate.instant('global.messages.submitted'));
                    loadGuide();
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $scope.writeBackPodChargesToPol = function() {
            var content = $translate.instant('autopsApp.guide.writeBackWmsChargesToGuideAlert') + "-" + $scope.guide.deliveryName;
            layer.confirm(content, {
                title: "Msg",
                icon:3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                GuideRates.writeBackPodChargesToPol({}, $scope.guide, function(result){
                    layer.msg($translate.instant('global.messages.submitted'));
                    loadGuide();
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $scope.autoCommission = function(ratio) {
            angular.forEach($scope.profitList, function(data){
                var newRate = {
                    isPay: true,
                    item: "销售提成",
                    unit: "SHIPMENT",
                    count: 1,
                    paymentTerm: "PPD",
                    inputBy: $rootScope.account.firstName + " " + $rootScope.account.lastName,
                    payExchange: 1,
                    hiddenRate: true
                };
                newRate.locked = newRate.isHidden = newRate.audited = newRate.allPaid = newRate.autoUpdate = newRate.clientChecked = false;
                newRate.currency = newRate.payCurrency = data.currency;
                newRate.price = newRate.total = newRate.payAmount = DataUtils.round(data.total * ratio, 2);
                $scope.guide.guideRates.push(newRate);
            })
            updateTotal();
            $translate.refresh();
        }

        $scope.unpaidSubshipmentCharges = function(ratio) {
            var entity = {
                isFms: $scope.shipmentType == "guide",
                masterId: $scope.guide.id,
                companyId: $scope.guide.companyId,
            };
            $uibModal.open({
                templateUrl: 'app/entities/assist-charge/assist-charge-detail.html',
                controller: 'AssistChargeDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {
                loadGuide();
            }, function() {
            });
        }

        $scope.hiddenChange = function(fd) {
            $scope[fd] = !$scope[fd];
            $timeout(function() {
                $translate.refresh();
            })
        }

        $scope.hedgingSharedCharges = function() {
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                GuideRates.hedgingSharedCharges({
                    isFms: $scope.shipmentType == "guide",
                    masterId: $scope.guide.id
                }, function(){
                    loadGuide();
                }, function(){
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                })
            }, function() {
            });
        }

        $scope._onChannelSelected = function(data) {
            $scope.guide.channel = data;
            ExpressParcel.updateByChannel($scope.guide, function(result) {
                $scope.guide = result;
                updateTotal();
            }, $rootScope.ALERT_ERROR);
        }
        $scope._onCostChannelSelected = function(data) {
            $scope.guide.costChannel = data;
            ExpressParcel.updateByChannel($scope.guide, function(result) {
                $scope.guide = result;
                updateTotal();
            }, $rootScope.ALERT_ERROR);
        }

        $scope._onWmsChannelSelected = function() {
            WmsIn.checkChannelValid($scope.guide, function(result) {
                $scope.guide = result;
                updateTotal();
            }, $rootScope.ALERT_ERROR);
        }

        $scope.addAutoCharges = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if ($scope.guide.isClosed || $scope.guide.ratesLocked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if($rootScope.HAVENOAUTH("AUTO_WMS_FEE")){
                $rootScope.NOT_ENABLED_ALERT("AUTO_WMS_FEE");
            }
            ExpressParcel.addAutoCharges($scope.guide, function(result) {
                $scope.guide = result;
            }, onAutoChargeError)
        }

        function onAutoChargeError(error){
            if(error.status == 400){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
            }
            else if(error.status == 403){
                layer.msg($translate.instant('global.lockedAlert'));
            }
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        }

        $scope.profitLossFb = function() {
            if(!$rootScope.account || !$rootScope.account.companyId){
                return true;
            }
            var haveHidden = false;
            angular.forEach($scope.guide.guideRates, function(rate){
                if(isHiddenRate(rate)){
                    haveHidden = true;
                }                
            });
            return haveHidden;
        };

        var isHiddenRate = function(rate) {
            if(rate.hiddenRate && $rootScope.HAVENOAUTH('FUN_VIEW_ONE_HIDDEN')){
                return true;
            }
            if(rate.id && $rootScope.HAVEAUTH('FB_VIEW_OTH_RATE')){
                var usrName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                if(usrName != rate.inputBy){
                    return true;
                }
            }
            if($scope.guide.hidenCost && $rootScope.HAVENOAUTH("FUN_VIEWCOST") && rate.isPay){
                return true;
            }
            if($scope.guide.hidenSelling && $rootScope.HAVENOAUTH("FUN_VIEWSELLING") && !rate.isPay){
                return true;
            }

            if($rootScope.HAVENOAUTH("AU_VIEW_EXP") && rate.isPay){
                return true;
            }
            if($rootScope.HAVENOAUTH("AU_VIEW_REV") && !rate.isPay){
                return true;
            }
            return false;
        }

        var dragIndex = null;
        $scope.onDragComplete = function(index, obj, $event, idx){
            dragIndex = idx;
        }
        $scope.onDropComplete = function(index, obj, $event, idx, isPay) {
            if(dragIndex == null || idx == null){
                return;
            }
            $scope.guide.guideRates.splice(idx, 0, $scope.guide.guideRates.splice(dragIndex, 1)[0]);
            updateRatesIdx();
            dragIndex = null;
            $translate.refresh();
        }

        $scope.dropSort = false;
        var guideRatesCopy = [];
        $scope.isDropChange = function(){
            updateRatesIdx();
            guideRatesCopy = angular.copy($scope.guide.guideRates);
            $scope.guide.guideRates = [];
            $translate.refresh();
        }
        $scope.isDropClick = function(fd){
            $scope.dropSort = !$scope.dropSort;
            $scope.guide.guideRates = guideRatesCopy;
            $translate.refresh();
        }

        function updateRatesIdx(){
            angular.forEach($scope.guide.guideRates, function(data, idx){
                data.idx = idx;
            })
        }

        $scope.fastWriteOff = function() {
            if ($rootScope.account.authorities.indexOf("FUN_WRITE_OFF") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_WRITE_OFF');
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var entty = {byDn: false};
            entty.guideRates = angular.copy($scope.guide.guideRates);
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountant/fast-write-off.html',
                controller: 'FastWriteOffController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entty;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('autoCheck');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                loadGuide();
            }, function() {
                loadGuide();
            });
        }
        $scope.updatePltCharges = function() {
            GuideRates.updatePltCharges({}, angular.copy($scope.guide), function(result){
                $scope.guide.guideRates = result.guideRates;
            })
        }
        $scope.salesCheckedDisabled = function() {
            if($scope.isSaving){
                return true;
            }
            if($rootScope.isDLX()){
                return $rootScope.account.id != $scope.guide.salesId && $rootScope.account.id != $scope.guide.cusId;
            }
            else {
                return $rootScope.account.id != $scope.guide.salesId;
            }
        }
        $scope.amassAddLabelCost = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            ExpressParcel.amassAddLabelCost({id: $scope.guide.id}, function(){
                layer.msg($translate.instant('global.update_success'));
                loadGuide();
            }, $rootScope.ALERT_ERROR);
        }
        $scope.revLockChange = function() {
            if ($rootScope.HAVENOAUTH('AU_REVLOCK')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_REVLOCK');
                $scope.guide.revLock = !$scope.guide.revLock;
            }
        }

        $scope.expLockChange = function() {
            if ($rootScope.HAVENOAUTH('AU_EXPLOCK')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_EXPLOCK');
                $scope.guide.expLock = !$scope.guide.expLock;
            }
        }


        $scope.revLocked = function() {
            return $rootScope.HAVENOAUTH('AU_FEE_FORCED_MODIFICATION') && $scope.guide.revLock;
        }
        $scope.expLocked = function() {
            return $rootScope.HAVENOAUTH('AU_FEE_FORCED_MODIFICATION') && $scope.guide.expLock;
        }

        $scope.ctnsNum = "";
        function getCtnsNum () {
            if ($scope.guide && $scope.guide.sayCtns) {
                $scope.ctnsNum = $scope.guide.sayCtns;
            }
            if ($scope.guide && $scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                $scope.ctnsNum = $scope.guide.guideCtns[0].ctnNum;
            }
        }


        //########### Asssist Charges #################
        $scope.sortAssistRates = function(fd) {
            isReverse = !isReverse;
            $scope.guide.assistCharges.sort(function(a, b) {
                if (isReverse) {
                    if(!a[fd]){
                        return 1;
                    }
                    return a[fd] < b[fd] ? 1 : -1;
                } else {
                    if(!b[fd]){
                        return 1;
                    }
                    return a[fd] > b[fd] ? 1 : -1;
                }
            });
        }
        $scope.addOneAssistCharge = function(isPay) {
            if (!$scope.guide.assistCharges) {
                $scope.guide.assistCharges = [];
            }
            var rate = {
                "payCurrency": $rootScope.account.baseCurrency,
                "unit": "BL",
                "count": 1,
                "isPay": isPay
            };
            $scope.guide.assistCharges.push(rate);
            $translate.refresh();
        };
        $scope.deleteAssistCharge = function(rate) {
            if (!rate.id) {
                var index = $scope.guide.assistCharges.indexOf(rate);
                if (index > -1) {
                    $scope.guide.assistCharges.splice(index, 1);
                };
                updateTotal();
                $translate.refresh();
                return;
            };
            if ($rootScope.HAVENOAUTH('AU_ASSIST_CHARGE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_ASSIST_CHARGE');
                return;        
            }
            var content = $translate.instant('autopsApp.booking.bookingPrice.delete.question');
            layer.confirm(content, {
                icon: 3,
                title: $translate.instant('autopsApp.booking.bookingPrice.delete.title'),
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                if (rate.id) {
                    AssistCharge.delete({
                        id: rate.id
                    });
                };
                var index = $scope.guide.assistCharges.indexOf(rate);
                if (index > -1) {
                    $scope.guide.assistCharges.splice(index, 1);
                };
                updateAssistChangeTotal();
                $translate.refresh();
            }, function() {
                return;
            });
        };
        $scope.autoInput = function() {
            if(!$scope.guide.guideRates || $scope.guide.guideRates.length == 0){
                $scope.addOne(true);
            }
            if (!$scope.guide.assistCharges) {
                $scope.guide.assistCharges = [];
            }
            var items = new Set();
            angular.forEach($scope.guide.assistCharges, function(data){
                if(data.item && !items.has(data.item)){
                    items.add(data.item);
                }
            })
            angular.forEach($scope.guide.guideRates, function(data){
                if(data.item && !items.has(data.item)){
                    items.add(data.item);
                    var rate = {
                        "item": data.item,
                        "unit": data.unit,
                        "count": data.count,
                        "payCurrency": data.payCurrency,
                        "isPay": true
                    };
                    $scope.guide.assistCharges.push(rate);
                    $translate.refresh();
                }
            })
        }
        $scope.assistChargesConfirmed = function() {
            Guide.assistChargesConfirmed({
                guideId: $scope.guide.id
            }, function(result) {
                $scope.guide.assistChargesConfirmed = result.assistChargesConfirmed;
                $scope.guide.assistChargeReviewer = result.assistChargeReviewer;
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        }
        $scope.assistAmountChange = function(rate) {
            if(rate.price == null){
                rate.payAmount = null;
                return;
            }
            rate.price = DataUtils.round(rate.price, 4);
            if (null == rate.count || rate.count < 0) {
                rate.count = 0.00;
            }
            rate.payAmount = DataUtils.round(rate.count * rate.price, 2);
            $scope.updateLastModifiedTime(rate);
            updateAssistChangeTotal();
        }
        $scope.assistPayAmountChange = function() {
            updateAssistChangeTotal();
        }

        var updateAssistChangeTotal = function() {          
            if(!$scope.guide.assistCharges && $scope.guide.assistCharges.length == 0){
                return;
            }  
            if(!exchangeRates && exchangeRates.length == 0){
                getExchangeRatesMap();
                return;
            }

            $scope.assistTotalList = [];
            $scope.assistProfitList = [];
            $scope.assistSellingList = [];
            $scope.assistProfit = {currency: $rootScope.account.baseCurrency};

            var chargesCopy = angular.copy($scope.guide.assistCharges);
            angular.forEach($scope.guide.guideRates, function(data){
                if(!data.isPay){
                    chargesCopy.push(data);
                }
            })

            var currencys = [];
            angular.forEach(chargesCopy, function(data) {
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);
                }
            })
            var cost = 0;
            var selling = 0;
            var roeAmt = 1;
            var profitTtl = 0;
            angular.forEach(currencys, function(currency) {
                cost = 0;
                selling = 0;
                roeAmt = exchangeRates[currency];
                angular.forEach(chargesCopy, function(rate) {
                    if (rate.payCurrency == currency && rate.payAmount) {
                        if (rate.isPay) {
                            cost = cost + rate.payAmount;
                            if(rate.exchangeRate){
                                profitTtl = profitTtl - (rate.payAmount * rate.exchangeRate);
                            }
                            else {
                                profitTtl = profitTtl - (rate.payAmount * roeAmt);
                            }
                            
                        } else {
                            selling = selling + rate.payAmount;
                            if(rate.exchangeRate){
                                profitTtl = profitTtl + (rate.payAmount * rate.exchangeRate);
                            }
                            else{
                                profitTtl = profitTtl + (rate.payAmount * roeAmt);
                            }                            
                        }                        
                    }
                })
                $scope.assistTotalList.push({
                    currency: currency,
                    total: DataUtils.round(cost, 2)
                });
                $scope.assistSellingList.push({
                    currency: currency,
                    total: DataUtils.round(selling, 2)
                });
                $scope.assistProfitList.push({
                    currency: currency,
                    total: DataUtils.round(selling - cost, 2)
                });
                $scope.assistProfit.ammount = DataUtils.round(profitTtl, 2);                
            })
        }
        
        $scope.testCustomizedFunction = function() {
            WmsIn.testCustomizedFunction({id: $scope.guide.id}, function(){
                loadGuide();
            }, $rootScope.ALERT_ERROR);
        }
        $scope.updateLastModifiedTime = function(rate) {
            if(!rate.id){
                return;
            }
            rate.lastModifiedTime = new Date();
        }
        $scope.checkDeductedCharge = function(rate) {
            if(!rate.id || !rate.venderId){
                return;
            }
            if(!rate.deductBillId && !rate.refundBillId){
                return;
            }
            GuideRates.checkDeductedCharge({
                guideRateId: rate.id,
                jobNum: $scope.shipmentType == "wms"? $scope.guide.warehousingNumber: $scope.guide.jobNum
            }, function(){
                layer.msg($translate.instant('global.messages.updateStatusSuccess'));
            }, $rootScope.ALERT_ERROR);
        }

    }
})();
