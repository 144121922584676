(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
                'footer@': {
                    templateUrl: 'app/layouts/navbar/footer.html',
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('wmsCargo');    
                    $translatePartialLoader.addPart('opsClient');
                    $translatePartialLoader.addPart('expressTracking');
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('user.management');
                    $translatePartialLoader.addPart('oaApplication');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('dataSubscription');
                    $translatePartialLoader.addPart('opsCost');
                    $translatePartialLoader.addPart('accountInvoice');
                    $translatePartialLoader.addPart('accountPayment'); 
                    $translatePartialLoader.addPart('gridState');
                    $translatePartialLoader.addPart('asyncTask');
                    $translatePartialLoader.addPart('autoCheck');
                    $translatePartialLoader.addPart('opsTrackStatus');
                    $translatePartialLoader.addPart('customNode');             
                    $translatePartialLoader.addPart('addressBook');    
                    $translatePartialLoader.addPart('opsCompany');                       
                }]
            }
        });
    }
})();
